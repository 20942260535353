.container {
  min-width: 28rem;
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  padding: var(--spacing-16);
  border-radius: 1.4rem;
  gap: var(--spacing-16);

  .labelAndIcon {
    display: flex;
    align-items: center;
    gap: var(--spacing-12);
  }

  .tagIcon {
    font-size: 3.2rem;
  }
}