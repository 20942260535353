.root {
  background-color: var(--color-white);
  box-shadow: var(--card-shadow);
  border-radius: var(--radius);
  padding: var(--spacing-16);
  height: 100%;
}

.title {
  position: absolute;
  font-size: 1.6rem;
  font-weight: 600;
}

.content {
  height: 100%;
}

.table {
  height: 100%;
}

.header {
  background-color: var(--color-grey-100);

  :global(.MuiDataGrid-columnHeaderTitle) {
    font-weight: 500;
    font-family: 'Inter';
  }
}