.root {
  text-transform: inherit;
  font-size: 1.4rem;

  &:global(.MuiButton-sizeSmall) {
    height: 3rem;
  }

  &:global(.MuiButton-outlinedPrimary) {
    color: var(--color-primary);
    background-color: var(--color-primary-outlined-button-bg);
  }

  &:global(.MuiButton-text:not(.Mui-disabled)) {
    color: var(--color-primary);
  }

  &:global(.MuiButton-colorError) {
    color: var(--color-error);
    border-color: var(--color-error);
  }

  &:global(.MuiButton-contained:not(.Mui-disabled)) {
    background-color: var(--color-primary);
  }
}