.container {
  min-width: 50rem;
}

.name {
  margin-top: var(--spacing-16);
  background-color: var(--color-grey-light-2);
  padding: var(--spacing-8);
  border-radius: 0.4rem;
}

.actions {
  padding: var(--spacing-24);
}