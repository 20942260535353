.generalInfo {
  background-color: var(--color-grey-100);
  padding: var(--spacing-16);
  border-radius: var(--radius-lg);
  margin-bottom: 4.8rem;

  .header {
    display: flex;
    justify-content: space-between;
  }
}

.heading {
  margin-bottom: var(--spacing-16);
}

.projectInfo {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--spacing-8) var(--spacing-24);

  .timezone {
    width: 30rem;

    &__readOnly {

      :global(.MuiSelect-icon) {
        display: none;
      }

      fieldset {
        border-width: 0 !important;
      }
    }
  }

  .latlang {
    padding: .85rem 1.4rem;

    .chooseMap {
      margin-left: 1.4rem;
    }
  }

  .value {
    padding: .85rem 1.4rem;
  }
}

.name {
  margin-top: 0;
  width: 30rem;

  &__readOnly {
    fieldset {
      border: none;
    }
  }
}

.label {
  font-weight: 500;
  padding-top: 0.7rem;
}

.coverImageHeading {
  font-size: 1.8rem;
  margin-bottom: var(--spacing-16);
}

.coverImageContainer {
  width: 30rem;
  aspect-ratio: 1.25;
  border-radius: 0.4rem;
}