.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  gap: var(--spacing-16);
}

.dateAndMilestoneBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .time {
    color: var(--color-text-secondary);
  }
}

.image_label {
  font-size: 1.4rem;
  border: 1px solid var(--color-grey-dark-6);
}

.tags {
  display: flex;
  justify-content: flex-start;
  gap: var(--spacing-12);
  padding: var(--spacing-16) 0;
  align-items: flex-start;
  border-bottom: 0.1rem solid var(--color-grey-dark-6);
  border-top: 0.1rem solid var(--color-grey-dark-6);

  .tagsLabel {
    color: var(--color-text-secondary);
    line-height: 3.2rem;
  }

  &>div {
    cursor: pointer;
    user-select: none;
  }
}

.actions {
  display: flex;
  justify-content: end;
}