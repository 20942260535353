.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  padding-top: var(--spacing-16) !important;
  background-color: var(--color-grey-light-2);
  margin-left: var(--spacing-24);
  margin-right: var(--spacing-24);
  border-radius: var(--spacing-4);
}

.actions {
  padding: var(--spacing-24);
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}