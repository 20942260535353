.container {
  display: flex;
  flex-direction: column;

  margin: 0 auto;
}

.columns {
  height: calc(100vh - (6.4rem + 3.6rem));
  display: flex;
  flex-direction: row;
  padding-top: var(--spacing-8); // for box-shadow of filters to visible
}

.sidenav {
  z-index: 1;
  overflow-y: auto;
}

.content {
  display: flex;
  flex: 1;
  height: 100%;
  padding: 0 var(--spacing-24);
  min-width: 0;
}