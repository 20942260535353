.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-24);
}

.tab {
    text-transform: none;
    font-size: 1.4rem;
    background-color: #F7F7F7;
    height: 3.6rem;
    min-height: 3.6rem;
}

.tabs {
    min-height: 3.6rem;
}