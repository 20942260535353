.figure {
  position: relative;

  video {
    vertical-align: middle;
  }

  figcaption {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: var(--spacing-8) var(--spacing-16);
    background: var(--color-bg-video-controls);
    z-index: 10;

    .controls {
      display: flex;
      justify-content: space-between;
      color: var(--color-white);

      .elapsed {
        margin-left: var(--spacing-8);
        font-size: 1.2rem;
        text-shadow: 0.2rem 0.1rem 0.2rem var(--color-grey-dark-1);
      }

      .controlButton {
        color: var(--color-white);
        background-color: var(--color-grey-dark-4);

        svg {
          font-size: 2rem;
        }
      }
    }
  }

  .progress {
    width: 100%;
    outline: none;
    cursor: pointer;
    height: 0.6rem;
  }
}