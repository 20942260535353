.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--spacing-16);
  padding: var(--spacing-16);
}

.search {
  width: 100%;
  font-size: 1.4rem;
  margin-top: 0;
}

.date {
  color: var(--color-grey-dark-2);
}

.milestoneList {
  font-size: 1.4rem;
  border: 0.1rem solid var(--color-grey-light-4);
  overflow-y: auto;
  flex-grow: 1;
}

.emptyMilestones {
  color: var(--color-grey-dark-2);
  padding-left: var(--spacing-16);
}

.listItem {
  padding-left: var(--spacing-8);
  padding-right: var(--spacing-8);
}

.listItemButton {
  display: flex;
  justify-content: space-between;
  padding-left: var(--spacing-8);
  padding-right: var(--spacing-8);
}

.milestoneName {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow-x: hidden;
}

.label {
  &:global(.MuiInputLabel-shrink) {
    transform: translate(1.4rem, -0.6rem) scale(0.75);
  }
}

.labelContent {
  display: flex;
  align-items: center;
}

.labelIcon {
  font-size: 1.3rem;
  margin-right: var(--spacing-4);
}

.endAdornment {
  visibility: hidden;
  cursor: pointer;

  :global(.MuiInputLabel-shrink + .MuiOutlinedInput-root) & {
    visibility: visible;
  }

  :global(.Mui-focused) & {
    color: var(--color-primary);
  }
}