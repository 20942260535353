.wrapperButton {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;

  .input {
    font-size: 1.4rem;
    border-radius: 0.6rem;
    background-color: var(--color-white);
    height: 4.8rem;
  }
}

:global(.con-time-range-picker-popover .MuiPaper-root) {
  width: 46rem;
  padding: var(--spacing-12);
}

:global(.con-time-range-picker-popover .MuiTypography-root) {
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
}

:global(.con-time-range-picker-popover .MuiSlider-valueLabelLabel) {
  font-size: 1.6rem;
}

:global(.con-time-range-picker-wrapper .MuiFormControl-root) {
  min-width: 18rem;
}

:global(.con-time-range-picker-wrapper .MuiTypography-root) {
  font-size: 1.6rem;
}

.endIcon {
  color: var(--color-primary);
}