:global(.MuiFilledInput-root) {

  > :global(.MuiSvgIcon-root) {
    font-size: 2rem;
    align-self: end;
    margin-bottom: var(--spacing-8);

    &:global(.MuiSelect-icon) {
      top: calc(25px - 0.5rem)
    }
  }

  > :global(.MuiInputAdornment-filled) {
    display: flex;
    align-items: end;
    align-self: end;
    margin-bottom: 0.6rem;

    > :global(.MuiSvgIcon-root) {
      margin-bottom: 0.2rem;
    }
  }
}

:global(.MuiInputLabel-filled) {
  font-size: 1.6rem;
  transform: translate(12px, 5px) scale(0.75);
}

:global(.Mui-error) {
  color: var(--color-error) !important;
}

:global(.MuiDateCalendar-root) {
  :global(.MuiPickersDay-root) {
    font-size: 1.4rem;
  }

  :global(.MuiDayCalendar-weekDayLabel) {
    font-size: 1.4rem;
  }

  :global(.MuiPickersCalendarHeader-label) {
    font-size: 1.4rem;
  }
}

:global(.MuiMultiSectionDigitalClock-root) {
  :global(.MuiMenuItem-root) {
    font-size: 1.4rem;
  }
}