.filters {
  display: grid;
  grid-template-columns: 22rem 20rem 18rem;
  gap: 1.6rem;
  background-color: var(--color-project-nav);
  border-radius: 0.6rem;
  padding: var(--spacing-16);

  .skeleton {
    transform: scale(1);
    height: 5rem;
  }
}