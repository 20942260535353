.container {
  flex-grow: 1;
  position: relative;
  min-width: 0;
  outline: 0;

  .stage {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: var(--color-grey-light-3);
    border-radius: var(--radius-lg);
  }

  .deleteButton {
    position: absolute;
    top: var(--spacing-8);
    left: var(--spacing-8);
    color: var(--color-black) !important;
    min-width: 3rem;
  }
}