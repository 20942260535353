.projectContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-48);
}

.coverImageContainer {
  width: 28rem;
  aspect-ratio: 1.25;
}

.image {
  transform: scale(1);
}