.imageCard {
  width: 100%;
  aspect-ratio: 16/9;
  flex-shrink: 0;
  border-radius: var(--spacing-4);
  overflow: hidden;
  background-color: var(--color-grey-light-2);
  position: relative;

  img {
    width: 100%;
    object-fit: contain;
  }

  .time {
    position: absolute;
    left: 0rem;
    bottom: 1rem;
  }
}