.actions {
  padding: var(--spacing-24);
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.milestoneInfo {
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-16);
  background-color: var(--color-grey-light-2);
  padding: var(--spacing-8);
  border-radius: 0.4rem;
}