.container {
  display: grid;
  grid-template-columns: 8fr 3fr 1fr;
  gap: 0.4rem;
}

.skeleton {
  height: 4rem;
  transform: scale(1);

  &:nth-child(-n+3) {
    height: 6rem;
  }
}