.root {
  background-color: var(--color-white);
  border-radius: var(--radius-lg);
  box-shadow: var(--card-shadow);
  padding: var(--spacing-16);
  min-width: 0;

  .heading {
    display: flex;
    justify-content: space-between;
    min-height: 5.2rem;

    .title {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }
}

.legend {
  display: flex;
  column-gap: var(--spacing-24);
  row-gap: var(--spacing-8);
  margin-top: var(--spacing-4);
  flex-wrap: wrap;

  .legendItem {
    display: flex;
    align-items: center;
    gap: var(--spacing-4);

    .marker {
      width: var(--spacing-12);
      height: 1.2rem;
      width: 1.2rem;
      border-radius: 50%;
      background-color: var(--color-non-compliance-by-date-chart-series);
    }
  }
}