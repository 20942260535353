.container {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-conxai-footer-bg);
  height: 3.6rem;
  padding: var(--spacing-8) var(--spacing-16);
  color: var(--color-white);
  align-items: center;

  a {
    color: inherit;
  }

  img {
    height: 3rem;
    object-fit: contain;
  }
}