.avatarButton {
  border-radius: 2.7rem;
  border: 0.1rem solid var(--color-grey-dark-7);
  color: var(--color-grey-dark-3);
  padding: 0.4rem;

  :global(.MuiButton-endIcon) {
    margin-right: 0;
    margin-left: 0.4rem;
  }

  .expand {
    transition: all .3s ease;
  }

  &:hover,
  &:focus,
  &__active {
    .expand {
      transform: translateY(0.2rem);
    }
  }
  
  .avatar {
    background-color: var(--color-primary);
    font-size: 1.6em;
    
  }
}


.loggingOutIcon {
  font-size: 1em;
  visibility: hidden;
  margin-left: 1rem;

  &__visible {
    visibility: visible;
  }
}