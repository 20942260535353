.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: var(--spacing-16);
  border-radius: 0.6rem;
  background-color: var(--color-white);
  box-shadow: 0 0.2rem 0.8rem 0 var(--color-grey-dark-4);
  position: relative;

  .videoWrapper {
    width: 100%;
    position: relative;
    visibility: visible;
    overflow: hidden;

    .box {
      position: absolute;
      z-index: 1;
      border: 0.3rem solid red;
      background-color: transparent;
    }

    video {
      width: 100%;
    }

    /*
    We are hiding video tag until we get first frame to prevent
    UI jumping because video aspect-ratio not equal to default video el aspect-ratio
    */
    &__loading {
      visibility: hidden;
    }
  }

  .spinner {
    position: absolute;
  }
}