.container {
  width: 100%;
  display: grid;
  grid-template-columns: auto 25rem;
  grid-template-rows: min-content auto;
  gap: var(--spacing-16);

  .header {
    display: flex;
    justify-content: space-between;
  }

  .titleAndDates {
    display: flex;
    gap: var(--spacing-16);
  }

  .actions {
    display: flex;
    gap: var(--spacing-16);
  }

  :global(.MuiSkeleton-root) {
    transform: none;
  }

  .cards {
    display: flex;
    gap: var(--spacing-16);
    flex-direction: column;
  }

  .card {
    height: 12rem;
  }

  .images {
    display: flex;
    gap: var(--spacing-8);
    flex-direction: column;
  }

  .image {
    height: 11rem;
    aspect-ratio: 18/9;
  }

  .title {
    width: 12rem;
  }

  .date {
    width: 8rem;
  }

  .button {
    width: 10rem;
  }
}