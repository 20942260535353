.popover {
  border-radius: 1.6rem;

  .popoverContent {
    font-size: 1.4rem;
    padding: var(--spacing-16);
    background-color: var(--color-middle-grey);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);

    .description {
      max-width: 34rem;
      color: var(--color-text-secondary);
    }

    .options {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-8);
      font-size: inherit;
    }
  }
}

.variantSelectIcon {
  font-size: 1.8rem;
}