.container {
  display: flex;
  gap: var(--spacing-12);
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
}

.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-16);
  transition: grid-template-rows 0.4s ease-in;
}

.contentInner {
  grid-column: 1/4;
  overflow: hidden;
  padding-top: 1px;
  min-height: 0;
}

.expandBtnIcon {
  transform: rotate(0deg);
  transition: transform 0.4s ease-in-out;

  &__expanded {
    transform: rotate(-180deg);
  }
}