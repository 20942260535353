.mapDialogPaper {
  max-width: 100vw;
  height: 70vh;
  width: 80vw;

  .mapDialogContent {
    position: relative;

    .closeButton {
      min-width: 3rem;
      position: absolute;
      top: var(--spacing-24);
      right: var(--spacing-32);
      z-index: 600;
    }
  }

  .map {
    width: 100%;
    height: 100%;
  }
}