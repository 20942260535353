.logo {
  display: flex;
  align-items: center;

  margin-right: var(--spacing-12);
}

.button {
  display: flex;
  background: none;
  margin-left: -0.8rem;


  &:hover, &.active {
    .expand {
      transform: translateY(0.6rem);
    }
  }

  .expand {
    transition: all .3s ease;
    transform: translateY(0.4rem);

  }
}

