.navItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;

  border-radius: 0.4rem;
  width: 3.6rem;
  height: 3.6rem;

  transition: all .3s ease;

  &>a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &:hover,
  &__active {
    &:not(.navItem__disabled) {
      .navItem__icon {
        &:before {
          transform: scale(1.05);
          opacity: 1;
        }
      }
    }
  }

  &__disabled {
    cursor: not-allowed;
    opacity: var(--opacity-disabled);
  }


  &__icon {
    display: flex;
    position: relative;
    transition: all .3s ease;
    padding: var(--spacing-4);
    border-radius: 0.4rem;

    svg {
      fill: var(--color-primary-30);
      z-index: 2;
      width: 2rem;
      height: 2rem;
    }

    &:before {
      transition: all .4s ease;
      border-radius: 0.4rem;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: scale(0);
      opacity: 0;
      content: '';
      background-color: var(--color-project-nav-active);
      z-index: 1;
    }
  }

  &>div {

    display: flex;
    justify-content: center;
    align-items: center;
    height: 5.6rem;
  }

  &__title {
    color: var(--color-text-primary);
    font-size: 1rem;
    font-weight: 500;
  }
}