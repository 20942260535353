.container {
  display: flex;
  justify-content: space-between;

  .cameraIcon {
    font-size: 1.6rem;
  }

  :global(.MuiSvgIcon-root) {
    font-size: 1.6rem;
  }

  .helpText {
    color: var(--color-text-secondary);
  }
}