.container {
  box-shadow: 0 0.2rem 0.8rem 0 var(--color-grey-dark-4);
  border-radius: 0.6rem;
  background-color: var(--color-white);
  min-width: 0;

  .title {
    font-size: 2rem;
    color: var(--color-text-primary);
  }

  .yaxisTitle {
    font-size: 1.2rem;
    text-align: center;
  }

  .xaxisLabel {
    font-size: 1.2rem !important;
  }
}