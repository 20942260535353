.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--spacing-16);
  aspect-ratio: 16/9;
  max-width: 100%;
}

.image {
  &__outer {
    position: relative;
    padding-top: 56.25%;
    margin: auto 0;
  }

  &__inner {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .imageWrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .openInButton {
      position: absolute;
      bottom: var(--spacing-8);
      right: var(--spacing-8);
      background-color: var(--color-timeline-image-zoom-button-bg);
      border-radius: var(--radius);
      z-index: 10;
    }
  }

  &__img {
    display: block;
    max-height: 100%;
    max-width: 100%;
    margin: 0 auto;

    &_loading {

      width: auto;
      height: 108rem;
    }
  }

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: inherit;
    height: inherit;

    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}