.grid {
  padding: 0 0 var(--spacing-48) 0;
  transition: all 222ms ease-in-out;
  display: grid;
  border-bottom: 0.1rem solid var(--color-grey-light-4);

  grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
  grid-gap: var(--spacing-24) var(--spacing-24);
  align-content: stretch;
  width: 100%;

  &>div {
    display: block;
    cursor: pointer;
  }
}

.date {
  padding: var(--spacing-16) 0;
  font-weight: 500;
}