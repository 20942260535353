.root {
  font-size: 1.4rem;
  min-width: 18rem;
}

.label {

  &:global(.MuiInputLabel-shrink) {
    padding-right: var(--spacing-8);
    opacity: 1;
  }
}

.dateSelect {
  background-color: var(--color-white);
}