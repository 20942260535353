.wrapper {
  padding: 0.6rem;
  position: relative;

  .time {
    position: absolute;
    left: 0.4rem;
    bottom: 0rem;
    font-size: 1rem;
  }
}