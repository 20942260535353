.imageContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: var(--spacing-16);
  width: 100%;
  height: 100%;

  .imageCard {
    border-radius: var(--spacing-8);
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--color-grey-light-2);
    justify-self: stretch;

    &__landscape {
      align-items: center;
    }
  }

  .leftSection {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-8);
    border-radius: var(--spacing-8);
    background-color: var(--color-grey-light-2);
    overflow: auto;

    .info {
      flex-grow: 1;
      display: flex;
      gap: var(--spacing-16);
      flex-direction: column;

      .label {
        color: var(--color-text-secondary);
      }
    }

    .createMilestoneBtn {
      align-self: flex-end;
    }
  }
}