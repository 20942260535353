.root {
  font-size: 1.4rem;

  &:global(.MuiChip-outlined) {
    border-color: var(--color-grey-dark-7);
  }

  :global(.MuiChip-icon) {
    margin-left: var(--spacing-8);
    font-size: 1.8rem;
  }
}