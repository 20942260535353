.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--spacing-16);
  margin-top: var(--spacing-8);

  @media (max-width: 1440px) {
    grid-template-columns: 1fr 1fr;
  }

  .imageCard {
    cursor: pointer;
  }

  .cameraName {
    font-size: 1.2rem;
    color: var(--color-text-secondary);
    margin-bottom: var(--spacing-4);
  }

  .noImage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--color-project-nav-active);
    color: var(--color-text-secondary);
    border-radius: 0.4rem;
    aspect-ratio: 16/9;

    .noImageIcon {
      font-size: 2.4rem;
      margin-bottom: var(--spacing-4);
    }
  }
}