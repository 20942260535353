.container {
  background-color: var(--color-project-nav);
  padding: var(--spacing-8);
  border-radius: var(--radius-lg);

  .title {
    font-weight: 700;
    margin-bottom: var(--spacing-8);
  }

  .content {
    background-color: var(--color-white);
    box-shadow: var(--card-shadow-status-report);
    padding: var(--spacing-16);
    border-radius: var(--radius-lg);
  }
}