.h1 {
  font-size: 2.4rem;
  letter-spacing: 0.05rem;
}

.h2 {
  font-size: 2.2rem;
  font-weight: 300;
  letter-spacing: 0.05rem;
}

.h3 {
  font-size: 2rem;
}

.h4 {
  font-size: 1.8rem;
  letter-spacing: 0.05rem;
}

.subtitle1 {
  font-size: 1.6rem;
}

.subtitle2 {
  font-size: 1.4rem;
  letter-spacing: 0.05rem;
}

.body1 {
  font-size: 1.8rem;
}

