.dates {
  display: flex;
  justify-content: space-between;
  width: 100%;

  >* {
    background-color: var(--color-grey-light-2);
    padding: var(--spacing-4);
    border-radius: var(--spacing-4);
  }
}

.carousel {
  display: flex;
  width: 100%;
  align-items: center;

  .imagelist {
    display: flex;
    gap: var(--spacing-8);
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 0.6rem;
  }

  .navButton {
    padding: var(--spacing-4);
  }

  .navIcon {
    font-size: 2rem;
  }
}

.carouselWindow {
  flex-grow: 1;
}