.dateInput {
  border-radius: 0.6rem;
  background-color: var(--color-white);

  :global(.MuiFilledInput-root::after) {
    border-bottom: 0;
  }
}

.dateTextfield {
  margin-top: 0;

  :global(.MuiFilledInput-root) {
    background-color: var(--color-white);
    border-radius: 0.6rem;
  }

  :global(.MuiFilledInput-root)::after {
    border-bottom: 0 !important;
  }

  :global(.MuiFilledInput-root)::before {
    border-bottom: 0 !important;
  }

  :global(.MuiInputAdornment-root) {
    margin-bottom: 0 !important;

    svg {
      color: var(--color-primary);
      font-size: 1.8rem;
    }
  }

  :global(.MuiButton-root) {
    font-size: 1.4rem;
  }

  input {
    padding-top: 2rem;
    padding-bottom: 0.2rem;
  }
}

.actionBar {
  :global(.MuiButton-root) {
    font-size: 1.4rem;
  }
}

.errorText {
  font-size: 1.2rem;
  width: 20rem;
}