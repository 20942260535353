.card {
  position: relative;
  background-color: var(--color-white);
  box-shadow: var(--card-shadow);

  transition: all .2s ease-in-out;

  border-radius: 0.8rem;
  overflow: hidden;

  width: 100%;

  &:hover {
    box-shadow: var(--card-hover-shadow);

    .cover__img {
      transform: scale(1.1);
    }
  }
}

.cover {
  height: 0;
  overflow: hidden;
  padding-top: 80%;
  position: relative;
  width: 100%;
  background-color: var(--color-grey-dark-7);

  &__img {
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    object-fit: cover;

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;

    transition: all 0.5s ease-out;

    &_final {
      animation: fadeIn 1s;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.description {
  position: absolute;
  bottom: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--spacing-8);
  align-items: center;

  width: 100%;

  padding: var(--spacing-12) var(--spacing-8) var(--spacing-8) var(--spacing-8);

  background: rgba(0, 0, 0, .5);

  &__icon {
    fill: var(--color-white);
    font-size: 3rem;
  }

  &__item {
    color: var(--color-white);
  }
}
