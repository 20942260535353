.popoverContent {
  display: flex;
  flex-direction: row;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--color-content-background);

    padding: 0 var(--spacing-24);
  }

  &__description {
    padding: var(--spacing-24);
    max-width: 30rem;
  }
}
