.container {
  display: grid;
  grid-template-columns: 23rem auto;
  grid-template-rows: auto 1fr;
  gap: var(--spacing-16);
  background-color: var(--color-project-nav);
  border-radius: 0.6rem;
  padding: var(--spacing-16);
}

.videoSection {
  grid-template-columns: 30rem auto;
}

.calibrationSection {
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
}
