.root {
  border-radius: var(--radius-2);
  background-color: var(--color-project-nav-active);
  padding: var(--spacing-16);

  .datetime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--spacing-16);
  }

  .camera {
    margin-bottom: var(--spacing-16);
  }
}