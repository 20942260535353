.root {
  padding: var(--spacing-16);
  border-radius: 0.6rem;
  background-color: var(--color-white);
  box-shadow: 0 0.2rem 0.8rem 0 var(--color-grey-dark-4);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);

  .liveChip {
    background-color: var(--color-success-light);
    margin-left: var(--spacing-16);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--spacing-16);
    border-radius: 1.6rem;
    font-size: 1.4rem;
    height: 2.8rem;
    color: var(--color-white);
  }

  .workersInsideContainer {
    line-height: 1.2;
    margin-top: var(--spacing-16);

    .workersInsideCountLabel {
      font-weight: 600;
    }

    .workersInsideCount {
      color: var(--color-primary-50);
      font-size: 6.4rem;
      font-weight: 600;
    }
  }

  .minMaxCounts {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.1rem dashed var(--color-divider);
    margin-bottom: var(--spacing-8);

    .countLabel {
      color: var(--color-text-secondary);
      font-weight: 500;
    }

    .count {
      color: var(--color-primary-secondary);
      font-size: 1.6rem;
      font-weight: 500;
    }
  }
}