.root {
  font-size: 1.4rem;
  border-radius: 0.6rem;

  &.variant__rounded {
    height: 4rem;
    border-radius: var(--radius-2);
  }
}

.icon {
  color: var(--color-primary);
  font-size: 1.8rem;
}