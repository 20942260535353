.info {
  display: flex;
  flex-direction: row;
  gap: 6rem;

  .complianceRate {
    font-size: 4rem;
    color: var(--color-primary-50);
    font-weight: 600;
  }

  .mostCommonIssueLabel {
    font-size: 2.4rem;
    margin-top: var(--spacing-16);
    margin-bottom: var(--spacing-8);
  }

  .subtext {
    display: flex;
    align-items: center;
    color: var(--color-text-secondary);
  }

  .warningIcon {
    color: var(--color-error-dark);
  }
}