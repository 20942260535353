.root {
  padding: var(--spacing-16);
  border-radius: 0.6rem;
  background-color: var(--color-white);
  box-shadow: 0 0.2rem 0.8rem 0 var(--color-grey-dark-4);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);

  .selectInfo {
    border-radius: 0.4rem;
    padding: 0.4rem 0.8rem;
    font-size: 1.2rem;

    :global(.MuiAlert-icon) {
      display: flex;
      align-items: center;
    }
  }

  .percentages {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.1rem dashed var(--color-divider);
    margin-bottom: var(--spacing-8);
    gap: var(--spacing-8);

    .percentageLabel {
      color: var(--color-text-secondary);
      font-weight: 500;
      text-wrap: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;

      .bulletPoint {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        margin-right: var(--spacing-8);
      }
    }

    .percentageValue {
      color: var(--color-text-secondary);
      font-size: 1.6rem;
      font-weight: 500;
      text-align: right;
    }
  }
}