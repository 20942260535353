.coverImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8);
  text-align: center;
  background-color: var(--color-grey-light-2);
  outline: 0.1rem solid var(--color-upload-outline);
  outline-style: dashed;
  outline-offset: -1rem;
  padding: var(--spacing-48);
  height: 100%;

  &__dragging {
    outline-color: var(--color-primary);
  }
}

.progress {
  width: 100%;
  border-radius: 9999px;
  background-color: var(--color-white);
}