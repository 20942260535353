.tagChip {
  background-color: var(--color-tag-default-background);
  color: var(--color-tag-default-font);
  position: relative;
  font-size: 1.4rem;

  &__hasCount {
    padding-right: 2.4rem;
  }

  .count {
    position: absolute;
    right: 0.3rem;
    top: 0.3rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 2.6rem;
    height: 2.6rem;
    background-color: var(--color-grey-dark-5);
    font-size: 1.2rem;
  }

  :global(.MuiChip-icon) {
    color: inherit;
    fill: currentColor;
  }

  &__equipments_selected,
  &__structures_selected,
  &__people_selected,
  &__objects_selected {
    svg {
      fill: rgba(255, 255, 255, .4);
      transition: all .2s ease;

      &:hover {
        fill: rgba(255, 255, 255, .8);
      }
    }
  }

  &__unknown {
    background-color: var(--color-tag-default-background);
    color: var(--color-tag-default-font);

    &_selected {
      background-color: var(--color-tag-default-selected-background);
      color: var(--color-tag-selected-font);
    }
  }

  &__equipment {
    background-color: var(--color-tagchip-equipment-background);
    color: var(--color-tagchip-equipment-text);

    &_selected {
      background-color: var(--color-tag-equipment-selected-dark);
      color: var(--color-tag-selected-font);
    }
  }

  &__objectsAndTools {
    background-color: var(--color-tag-objectsAndTools-selected-background);
    color: var(--color-tag-objectsAndTools-selected-dark);

    &_selected {
      background-color: var(--color-tag-objectsAndTools-selected-dark);
      color: var(--color-tag-selected-font);
    }
  }

  &__structures {
    background-color: var(--color-tag-structures-selected-background);
    color: var(--color-tag-structures-selected-dark);

    &_selected {
      background-color: var(--color-tag-structures-selected-dark);
      color: var(--color-tag-selected-font);
    }
  }

  &__hoisting {
    background-color: var(--color-tag-hoisting-selected-background);
    color: var(--color-tag-hoisting-selected-dark);

    &_selected {
      background-color: var(--color-tag-hoisting-selected-dark);
      color: var(--color-tag-selected-font);
    }
  }

  &__vehicle {
    background-color: var(--color-tag-vehicle-selected-background);
    color: var(--color-tag-vehicle-selected-dark);

    &_selected {
      background-color: var(--color-tag-vehicle-selected-dark);
      color: var(--color-tag-selected-font);
    }
  }

  &__person {
    background-color: var(--color-tagchip-person-background);
    color: var(--color-tagchip-person-text);

    &_selected {
      background-color: var(--color-tag-person-selected-dark);
      color: var(--color-tag-selected-font);
    }
  }
}