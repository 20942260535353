.cameraNameContainer {

  .cameraName {
    display: inline-flex;
    align-items: center;
    padding: 0.6rem var(--spacing-8);
    background-color: var(--color-grey-dark-7);
    border-radius: 0.4rem;
    cursor: pointer;
    height: 3.4rem;
    gap: var(--spacing-8);

  }
}

.nameField {
  background-color: var(--color-white);
  border-radius: 0.4rem;
  margin-top: 0;

  :global(.MuiOutlinedInput-notchedOutline) {
    border: none;
  }
}