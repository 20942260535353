.container {
  display: flex;
  justify-content: space-between;
  align-items: start;
  border-radius: 0.8rem;
  background-color: var(--color-white);
  box-shadow: var(--color-saved-search-drop-shadow);
  margin: 0 var(--spacing-4) var(--spacing-8) var(--spacing-4);
  overflow: hidden;
  position: relative;

  &:hover,
  &__selected {
    background-color: var(--color-icon-background);
  }
}

.applyBtn {
  color: var(--color-neutral-10) !important;
  align-items: start;
  text-align: left;
  justify-content: left;
  padding: var(--spacing-12) var(--spacing-4) var(--spacing-12) var(--spacing-16);

  &:hover {
    background-color: inherit;
  }

  :global(.MuiButton-startIcon) {
    padding-top: 0.3rem;

    svg {
      font-size: 1.4rem;
      color: var(--color-saved-search-icon);
    }

  }

  :global(.MuiButton-endIcon) {
    padding-top: 0.3rem;

    svg {
      color: var(--color-last-used-saved-search-icon);
      font-size: 1.3rem;
    }

  }
}

.name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  // fallback
  line-height: 2rem;
  max-height: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;

  flex-grow: 1;
}

.deleteBtn {
  min-width: 3rem;
  align-self: stretch;
  align-items: start;
  padding: var(--spacing-12);

  svg {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 1080px) {
  .deleteBtn {
    display: none;
  }

  .applyBtn {
    padding-right: var(--spacing-16);
  }

  .container:hover {
    .deleteBtn {
      background-color: var(--color-saved-search-delete-bg);
      position: absolute;
      left: 100%;
      transform: translateX(-100%);
      display: flex;
      z-index: 10;
    }
  }
}