.root {
  font-size: 1.4rem;
  margin-top: 0.9rem;

  :global(.MuiFormLabel-root) {
    &:global(.MuiInputLabel-outlined) {
      font-size: inherit;
    }

    &.Mui-focused {
      color: var(--color-primary);
    }
  }

  :global(.MuiInputBase-root) {
    font-size: inherit;
    height: 4.8rem;

    &:global(.MuiInputBase-sizeSmall) {
      height: 3.4rem;
    }
  }

  :global(.MuiOutlinedInput-root.Mui-focused) {
    :global(.MuiOutlinedInput-notchedOutline) {
      border-color: var(--color-primary);

    }

    &:global(.Mui-error) {
      :global(.MuiOutlinedInput-notchedOutline) {
        border-color: var(--color-error);
      }
    }

    :global(.MuiSvgIcon-root) {
      color: var(--color-primary);
    }
  }

  :global(.MuiFormHelperText-root) {
    font-size: 1rem;
  }

  :global(.Mui-error) {
    color: var(--color-error);
  }

}