.form {
  :global(fieldset) {
    border-color: var(--color-white);
  }

  :global(.MuiInputBase-root) {
    background-color: var(--color-white);
  }

  :global(.MuiFormHelperText-root) {
    font-size: 1.2rem;
  }

  .cameraControl {
    .cameraLabel {
      font-size: 1.4rem;
    }

    .cameraSelect {
      height: 4.8rem;
    }
  }
}

.actions {
  padding: var(--spacing-24);
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.endAdornment {
  display: flex;
  align-items: center;
}


.dateInput:not(:global(.Mui-disabled)) .endAdornment {
  color: var(--color-primary);
}

.endIcon {
  font-size: 1.8rem;
}

.hourSelect {
  margin-top: 0.9rem;
  height: 4.8rem;
}

.datetime {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: var(--spacing-16);
}