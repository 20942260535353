.dateInput {
  border-radius: 0.6rem;
  background-color: var(--color-white);
}

.endAdornment {
  display: flex;
  align-items: center;

  .endAdornmentDate {
    color: var(--color-grey-dark-2);
    margin-right: var(--spacing-16);
  }

  .endIcon {
    font-size: 1.8rem;
    color: var(--color-primary);
  }
}

.textfield {
  min-width: 20rem;
  margin-top: 0;
}

.content {
  display: flex;
  padding: 0;
  height: 35rem;
}