.container {
  display: flex;
  flex-direction: column;

  margin: 0 auto;
}

.columns {
  height: calc(100vh - (6.4rem + 3.6rem));
  display: flex;
  flex-direction: row;
}

.content {
  display: flex;
  flex: 1;

}