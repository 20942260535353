.root {

  &:global(.Mui-readOnly::after) {
    border-bottom: none;
  }

  &::before {
    border: none !important;
  }

  :global(input) {
    padding: var(--spacing-8);
    font-size: 2.4rem;
    color: var(--color-text-primary-2);
    font-family: "Inter", sans-serif;
    width: 8rem;
    text-align: center;
  }
}