.container {
  padding: var(--spacing-24) 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
  grid-gap: var(--spacing-24) var(--spacing-24);
  align-content: stretch;
  width: 100%;
  overflow-y: auto;

  .item {
    display: block;

    &__skeleton {
      padding-top: 80%;
      border-radius: 0.8rem;
    }
  }

  &_empty {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
}