@mixin list($bullet: "") {
  list-style: none;

  li {
    display: flex;
    align-items: center;
    line-height: 3.2rem;

    .message {
      width: 100%;
      padding-left: var(--spacing-4);
      line-height: 3rem;
    }

    &::before {
      content: $bullet;
      font-size: 0.8em;
      color: var(--color-text-focus-visible);
      padding: 0 var(--spacing-8);
      border-radius: var(--radius-lg);
    }
  }
}

.container {
  background-color: var(--color-project-nav);
  border-radius: var(--radius-lg);
  padding-bottom: var(--spacing-8);

  .title {
    padding: var(--spacing-8);
    border-bottom: 0.1rem solid var(--color-divider);
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--color-text-primary-2);
  }

  .editable {
    position: relative;
    border-radius: var(--radius-lg);

    .deleteButton {
      visibility: hidden;
    }

    .message {
      width: 100%;
      padding-left: var(--spacing-4);
      border-radius: var(--radius-lg);
      line-height: 3rem;

      &:focus {
        background-color: var(--color-white);

        &+.deleteButton {
          visibility: visible;
        }
      }
    }

    &:hover {

      .message {
        background-color: var(--color-white);

      }

      .deleteButton {
        visibility: visible;
      }
    }
  }

  .infos {
    padding: var(--spacing-8) var(--spacing-16);
    @include list("◼");

    .addInfoButton {
      background-color: var(--color-white);
      color: var(--color-text-disabled);
      border-radius: 0.6rem;

      .addInfoInput {
        width: 100%;
        line-height: inherit;
        border: none;
        margin-left: calc(var(--spacing-8) * -1);
        margin-right: var(--spacing-8);
        padding-left: var(--spacing-8);
      }
    }
  }

  .observationAccordion {
    background-color: var(--color-warning-hover);
    margin: var(--spacing-16);
    box-shadow: none;
    border-radius: 0.6rem;
    margin-top: 0;

    .observationTitleWrapper {
      justify-content: left;
      gap: var(--spacing-8);
      min-height: 4rem;

      .obserationTitle {
        display: flex;
        align-items: center;
        gap: var(--spacing-8);
        font-weight: 500;
      }

      .expandIcon {
        font-size: 2rem;
      }
    }

    .obserations {
      @include list("→");
      padding-left: var(--spacing-8);
    }

    :global(.MuiAccordionSummary-content) {
      flex-grow: 0;
      margin: 0;
    }

    :global(.MuiAccordionDetails-root) {
      padding-top: 0;
    }

    &::before {
      top: 0;
      height: 0;
    }

    .warningIcon {
      color: var(--color-warning-light);
      font-size: 1.8rem;
    }
  }
}

.emptyObservations {
  padding: 0 var(--spacing-16);

  .addNewObservations {
    padding-left: var(--spacing-24);
    list-style-type: none;
    position: relative;
    line-height: 3.2rem;

    &::before {
      content: "⚠";
      position: absolute;
      left: 0.4rem;
    }
  }
}