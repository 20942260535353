.container {
  height: 6.4rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 var(--spacing-12);
}

.projectHeader {
  margin-left: var(--spacing-12);
  flex-grow: 1;
}

.navButton {
  font-size: 1.6rem;
  letter-spacing: 0.05rem;
  font-style: normal;
  text-decoration: none;
  text-transform: none;

  color: var(--color-nav-button-text);
}

.actionsContainer {
  display: flex;
  gap: var(--spacing-16);
  align-items: center;
}
