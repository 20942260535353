.container {
  display: grid;
  grid-template-columns: 24rem auto;
  grid-template-rows: 40rem 40rem;
  gap: var(--spacing-16);
  padding: var(--spacing-16);
  background-color: var(--color-project-nav);
  border-radius: 0.6rem;

  .skeleton {
    transform: scale(1);
  }
}