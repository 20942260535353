.container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr;
  gap: 0.4rem;
}

.skeleton {
  height: 4rem;
  transform: scale(1);

  &:nth-child(-n+6) {
    height: 6rem;
  }
}