.root {
  border-radius: var(--radius-2);
  background-color: var(--color-project-nav-active);
  font-size: 1.4rem;

  .sectionTitle {
    padding: var(--spacing-8) var(--spacing-16);
    border-bottom: 0.1rem solid var(--color-search-section-title);
  }

  .operatorSelect {
    background-color: var(--color-white);
    min-width: 11rem;
  }

  .tagsRow {
    display: grid;
    grid-template-columns: min-content auto;
    gap: var(--spacing-8);
    align-items: center;
    padding: var(--spacing-16);

    .tagsContainer {
      display: grid;
      grid-template-columns: auto min-content;
      gap: var(--spacing-8);
      background-color: var(--color-white);
      padding: var(--spacing-8);
      border-radius: var(--radius-2);
      min-height: 4.8rem;
      border: 0.2rem solid white;
      align-items: center;

      &__active {
        border: 0.2rem solid var(--color-primary);
      }

      .tags {
        display: flex;
        align-items: center;
        gap: var(--spacing-12);
        flex-wrap: wrap;
      }

      .empty {
        min-height: 3.2rem;
        display: flex;
        align-items: center;
        padding-left: var(--spacing-8);
      }

      .clearTagsBtn {
        color: var(--color-primary);
        padding: var(--spacing-4);

        svg {
          font-size: 1.8rem;
        }
      }
    }

    .tagSeparator {
      color: var(--color-search-tag-separator);
    }
  }

  .saveBtnWrapper {
    width: 100%;
    text-align: right;
    padding: var(--spacing-16);

    .saveBtn {
      padding: 0;
    }
  }
}