.container {
  display: flex;
  flex-direction: column;
  flex: 1;

  gap: var(--spacing-40);

  padding: var(--spacing-12) var(--spacing-16) 0 var(--spacing-16);

  max-width: 120rem;
}