:root {
  --spacing-48: 48px;
  --spacing-40: 40px;
  --spacing-32: 32px;
  --spacing-24: 24px;
  --spacing-16: 16px;
  --spacing-12: 12px;
  --spacing-8: 8px;
  --spacing-4: 4px;
  --spacing-2: 2px;

  --card-shadow: 0 1px 1px 0 rgba(0, 0, 0, .04), 0 1px 3px 1px rgba(0, 0, 0, .04);
  --card-hover-shadow: 0 1px 3px 1px rgba(0, 0, 0, .1), 0 2px 8px 4px rgba(0, 0, 0, .04);
  --card-shadow-status-report: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);


  --opacity-disabled: 0.4;

  --form-field-border-radius: 0.4rem;

  --tag-card-height: 12rem;

  --radius-2: 1.6rem;
  --radius: 0.4rem;
  --radius-lg: 0.6rem;
}