.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);

  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tab {
    font-weight: 600;
  }
}