.formControl {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-16);
  align-items: center;

  .label {
    font-size: 1.6rem;
    font-weight: 700;
  }
}