.container {
  display: flex;
  flex-grow: 1;
  max-width: 100%;
  overflow-y: auto;

  .menu {
    width: 25.6rem;
    background-color: var(--color-grey-light-2);
    font-weight: 500;
    padding-top: var(--spacing-24);

    .menuButton {
      height: 5.6rem;
      color: var(--color-primary-selected);
      font-size: 1.6rem;

      &:global(.Mui-selected) {
        background-color: var(--color-icon-background);
        color: var(--color-icon-color);
      }
    }
  }

  .main {
    flex-grow: 1;
    padding: var(--spacing-24);
    padding-right: 0;
    font-size: 1.4rem;
    overflow-y: auto;
  }
}