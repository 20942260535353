.container {
  height: 6.4rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 var(--spacing-12);
}

.actionsContainer {
  display: flex;
  gap: var(--spacing-16);
  align-items: center;
}
