.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-16);

  .addIcon {
    font-size: 1.6em;
  }
}

.confirmationStatus {
  &:before {
    display: inline-block;
    content: '';
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    margin-right: var(--spacing-8);
  }

  &__pending::before {
    background-color: var(--color-user-status-not-confirmed);
  }
}

.statusHeader {
  &:global(.MuiDataGrid-columnHeader) {
    padding-left: var(--spacing-24);
  }
}

.deleteIcon {
  font-size: 1.8rem;
}