.calendar {
  font-size: 1.4rem;

  :global(.MuiPickersDay-root) {
    font-size: inherit;
  }

  :global(.MuiPickersCalendarHeader-labelContainer) {
    font-size: inherit;
  }

  :global(.MuiDayCalendar-weekDayLabel) {
    font-size: inherit;
  }

  :global(.MuiPickersYear-yearButton) {
    font-size: inherit;
  }

  :global(.MuiPickersArrowSwitcher-button) {
    font-size: 1.2em;
  }


  :global(.MuiPickersDay-today) {
    border-color: var(--color-primary-30);
    color: var(--color-primary-30);
  }

  :global(.Mui-selected) {
    background-color: var(--color-primary-50);
    color: white;
  }
}

.dayWithEvent {
  position: relative;

  &::before {
    content: '•';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0.5em;
    color: #FFC145;
    font-size: 1.6em;
  }

}

.eventList {
  max-width: 30rem;

  .eventName {
    border-bottom: 0.1rem solid var(--color-grey-light-3);

    &:last-child {
      border-bottom: none;
    }
  }
}

.eventListPopper {
  z-index: 2000;

  .eventNamePaper {
    font-size: 1.2rem;
  }
}