.pdfContainer {
  display: none;
  margin: 0;
  padding: 0;
}

.reportContainer {
  position: relative;

  color: var(--color-text-primary-2);
  font-family: Inter, 'sans-serif';

  background-color: transparent;
  margin: auto;
  width: 100%;
  font-size: 1.4rem;
}

.header,
.poweredByConxai {
  width: 100%;

  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  /* 3.2rem */
  letter-spacing: 0.015rem;

  display: flex;
  justify-content: space-between;

  &Section {
    display: flex;
    gap: var(--spacing-8);
    font-size: 1.8rem;
    font-weight: 500;

    :last-child {
      background-color: var(--color-grey-light-2);
      padding: 0 var(--spacing-4);
      border-radius: var(--radius);
    }
  }
}

.poweredByConxai {
  margin: 0 0 var(--spacing-12) 0;
  padding: 0 0 var(--spacing-8) 0;
  width: 100%;
  border-bottom: 1px solid var(--color-grey-dark-4);

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--spacing-12);

  font-size: 1.4rem;
  font-weight: 400;
}

.logo {
  width: 50px;
  height: auto;
}

.pageContent {
  padding: 0 20px;
}

.projectInfo {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  padding: var(--spacing-12) 0 var(--spacing-24);
}

.summary {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);

  &Title {
    font-size: 1.6rem;
    font-weight: 500;
  }

  p {
    padding: 0 0 0 var(--spacing-24);
  }
}

.blocks {
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  margin: var(--spacing-24) 0 var(--spacing-12);

  border: 1px solid var(--color-grey-dark-4);
  border-radius: var(--radius-lg);

  .block:not(:last-child) {
    border-right: 1px solid var(--color-grey-dark-4);
  }


}

.block {
  display: flex;
  padding: .4rem .2rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 0;

  :first-child {
    display: flex;
    align-self: center;
    justify-content: center;
    color: var(--color-text-secondary);
    font-size: 1.4rem;
    flex: 1;
    text-align: center;
  }

  :last-child {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--color-text-primary);

    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.015rem;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  padding: var(--spacing-12) 0;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .sectionTitle {
    font-size: 1.6rem;
    font-weight: 500;
    border-bottom: 1px solid var(--color-grey-dark-4);
    padding-bottom: var(--spacing-2);
  }

  .sectionSubTitle {
    width: 100%;
    font-size: 1.4rem;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .sectionContent {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-12);
    padding: 0 0 0 var(--spacing-24);

    ul {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-4);
    }
  }

  .sectionItem {
    &:before {
      content: "◼";
      font-size: 0.8em;
      color: var(--color-text-focus-visible);
      margin-left: -2.4rem;
      margin-right: 1.4rem;
    }

    line-height: 2.4rem;
    padding-left: var(--spacing-24);
  }

  .sectionObservationItem {
    &:before {
      content: "→";
      font-size: 0.8em;
      color: var(--color-text-focus-visible);
      margin-left: -2.4rem;
      margin-right: 1.4rem;
    }

    line-height: 2.4rem;
    padding-left: var(--spacing-24);
  }
}

.warningIcon {
  color: var(--color-warning-light);
  font-size: 1.6rem;
  margin-right: var(--spacing-8);
}

.moreDetails {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  align-items: flex-start;
  justify-content: center;
  font-size: 1.4rem;
  padding: var(--spacing-12) 0;
  font-weight: 500;
  page-break-inside: avoid;

  a {
    color: var(--color-primary-40);
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.017rem;
    text-decoration-line: underline;
  }
}

.conxaiLogo {
  width: 10rem;
}