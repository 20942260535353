$element-size: 3.6rem;

.navItem {
  z-index: 1;
  position: absolute;
  bottom: var(--spacing-48);
  left: calc((6.4rem / 2) - ($element-size / 2) - .2rem);

  width: $element-size;
  height: $element-size;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  transition: none;

  margin-right: -1.6rem;

  border-radius: 50%;

  svg {
    transform: translateX(.25rem);
  }

  background-color: var(--color-white);
  border: 2px solid var(--color-grey-dark-6);

  &:hover {
    background-color: var(--color-primary-40);

    svg {
      fill: var(--color-white);
    }

  }

  &__opened {
    transition-delay: .185s;
    left: calc(36rem + 6.4rem - ($element-size / 2) - .2rem);
  }

  &__closed {
    svg {
      transform: translateX(.12rem);
    }
  }

  &__disabled {
    display: none;
  }

  &__animated {
    transition: all .6s ease;
  }
}
