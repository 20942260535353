.container {
  display: flex;
  align-items: center;
  padding: var(--spacing-8) 10px;
}

.icon {
  width: var(--spacing-24);
  height: var(--spacing-24);
  color: var(--color-primary-40);
}

.iconContainer {
  display: flex;
  padding: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8);
  cursor: pointer;
}

.label {
  color: var(--color-primary-40);
  cursor: pointer;
  text-transform: uppercase;
  &:global(.MuiTypography-root) {
    font-size: 14px;
    font-weight: 500;
    line-height: var(--spacing-24);
    letter-spacing: 0.4px;
  }
}
.item {
  &:global(.MuiMenuItem-root) {
    font-size: var(--spacing-16);
    line-height: var(--spacing-24);
  }
}
