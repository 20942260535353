.wrapperButton {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;

  input {
    font-size: 1.6rem;
    line-height: 1.6rem;
    height: auto;
    min-height: auto;

    padding: 14.5px 14px;
  }
}

:global(.con-date-range-picker-wrapper .MuiFormControl-root) {
  width: 26rem;
}

:global(.con-date-range-picker-wrapper .MuiFormLabel-root) {
  font-size: 1.6rem;
  line-height: 1.6rem;
}

:global(.con-date-range-picker-wrapper .MuiInputLabel-shrink) {
  transform: translate(1.4rem, -0.6rem) scale(0.75);
}

:global(.con-date-range-picker-wrapper .MuiOutlinedInput-notchedOutline) {
  legend {
    font-size: 1.2rem;
  }
}

:global(.con-date-range-picker-wrapper .MuiTypography-root) {
  font-size: 1.6rem;
}

