.filters {
  display: flex;
  background-color: var(--color-project-nav);
  align-items: start;
  border-radius: var(--radius-2);
  padding: var(--spacing-16);
  gap: var(--spacing-16);

  .cameraSelect {
    min-width: 22rem;
  }

  .attrSelect {
    align-self: center;
  }
}