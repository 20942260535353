.card {
  position: relative;
  background-color: var(--color-white);
  box-shadow: var(--card-shadow);

  transition: all .2s ease-in-out;

  border-radius: 0.8rem;
  overflow: hidden;
  aspect-ratio: 16/9;
  width: 100%;

  &:hover {
    box-shadow: var(--card-hover-shadow);

    .cover__img {
      transform: scale(1.05);
    }
  }
}

.cover {
  width: 100%;
  height: 100%;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.description {
  position: absolute;
  top: var(--spacing-4);
  right: var(--spacing-4);

  .time {
    margin-left: var(--spacing-4);
    color: var(--color-white);
  }

  &__date {
    font-size: 1.2rem;
    line-height: normal;
  }

}

.camera {
  position: absolute;
  top: var(--spacing-4);
  left: var(--spacing-4);
  font-size: 1.2rem;
  line-height: normal;
}