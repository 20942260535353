.container {
  display: flex;
  flex-direction: column;
}

.name {
  color: var(--color-primary-20);
}

.location {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-2);
  margin-left: -2px;

  svg {
    font-size: 1.6rem;
    fill: var(--color-text-secondary);
  }


  h6 {
    line-height: normal;
    color: var(--color-text-secondary);
  }
}

