.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-grey-light-2);
  width: 100%;
  height: 100%;

  >img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover {
    >img {
      opacity: 0.5;
    }

    .deleteButton {
      visibility: visible;
    }
  }
}

.deleteButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  visibility: hidden;
}

.actions {
  gap: var(--spacing-16);
  padding: var(--spacing-16);
}