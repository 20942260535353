.container {
  background-color: var(--color-grey-light-2);
  border-bottom: 0.1rem solid var(--color-divider);
  padding: var(--spacing-8);
  display: flex;
  align-items: center;

  .icon {
    font-size: 2rem;
  }

  .date {
    flex-grow: 1;
    text-align: center;
  }
}