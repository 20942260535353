:root {
  --color-primary: rgb(24, 144, 255);
  --color-primary-main: rgb(33, 150, 243);
  --color-primary-dark: rgba(0, 106, 204, 1);
  --color-primary-50: #0084ff;
  --color-primary-40: #006ACC;
  --color-primary-30: #004F99;
  --color-primary-20: #003566;
  --color-blue-selected: rgba(51, 157, 255, 1);
  --color-primary-selected: #004f99;
  --color-primary-selected-background: #f0f8ff;
  --color-divider: rgba(0, 0, 0, 0.12);
  --color-warning-light: rgba(255, 152, 0, 1);
  --color-warning-hover: rgba(239, 108, 0, 0.04);
  --color-disabled: rgba(0, 0, 0, 0.12);

  --color-neutral-10: #191919;

  --color-primary-light: #FF3366;

  --color-error: #861313;

  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f7f7f7;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;

  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: rgb(153, 153, 153);
  --color-grey-dark-4: rgba(0, 0, 0, .15);
  --color-grey-dark-4-1: rgba(0, 0, 0, .23);
  --color-grey-dark-5: rgba(0, 0, 0, .05);
  --color-grey-dark-6: rgb(233, 234, 237);
  --color-grey-dark-7: rgb(230, 230, 230);
  --color-grey-dark-8: rgba(0, 0, 0, 0.67);
  --color-middle-grey: #EFEFEF;
  --color-grey-100: #F5F5F5;
  --color-text-focus-visible: rgba(0, 0, 0, 0.3);

  --color-green-500: rgba(76, 175, 80, 1);
  --color-success-light: rgba(76, 175, 80, 1);
  --color-error-dark: rgba(198, 40, 40, 1);

  --color-warning-alert-background: rgba(255, 244, 229, 1);
  --color-warning-alert-text: rgba(102, 60, 0, 1);
  --color-elevation-outlined: rgba(224, 224, 224, 1);

  --color-project-nav: rgba(247, 247, 247, 1);
  --color-project-nav-active: rgba(239, 239, 239, 1);


  --color-text-primary: rgb(76, 76, 76);
  --color-text-primary-2: rgba(0, 0, 0, 0.87);
  --color-text-secondary: rgba(0, 0, 0, .6);
  --color-text-disabled: rgba(0, 0, 0, .38);

  --color-nav-button-text: rgba(102, 102, 102, 102, 1);

  --color-image-overflow-box: rgba(0, 0, 0, .6);

  --color-content-background: #F6F9FC;

  --color-white: #FFF;
  --color-black: rgb(0, 0, 0);

  --color-tag-card: rgb(246, 249, 252);
  --color-tag-card-selected: rgb(204, 230, 255);
  --color-tag-card-border: rgb(224, 224, 224);
  --color-tag-selected-font: rgba(255, 255, 255);
  --color-tag-default-background: rgba(0, 0, 0, .1);
  --color-tag-default-font: rgba(0, 0, 0, .5);
  --color-tag-default-selected-background: rgba(0, 0, 0, .5);

  //Equipment
  --color-tagchip-equipment-background: rgba(2, 136, 209, 0.08);
  --color-tagchip-equipment-text: rgba(1, 87, 155, 1);
  --color-tagchip-equipment-border: rgba(1, 87, 155, 1);
  --color-tag-equipment-selected-background: rgba(204, 230, 255, 1);
  --color-tag-equipment-selected-border: rgba(24, 144, 255, 1);
  --color-tag-equipment-selected-dark: rgba(0, 72, 138, 1);

  //Objects and Tools
  --color-tag-objectsAndTools-background: rgba(245, 251, 248);
  --color-tag-objectsAndTools-font: rgba(84, 122, 90);
  --color-tag-objectsAndTools-selected-background: rgba(46, 125, 50, 0.08);
  --color-tag-objectsAndTools-selected-border: rgba(68, 136, 79, 1);
  --color-tag-objectsAndTools-selected-dark: rgba(83, 122, 90, 1);

  // Structures
  --color-tag-structures-background: rgba(245, 240, 255);
  --color-tag-structures-font: rgba(104, 50, 123);
  --color-tag-structures-selected-background: rgb(245, 240, 255);
  --color-tag-structures-selected-border: rgba(104, 50, 123, 1);
  --color-tag-structures-selected-dark: rgba(104, 50, 123, 1);

  // Person
  --color-tag-person-selected-background: rgba(255, 234, 216, 1);
  --color-tag-person-selected-border: rgb(239, 108, 0);
  --color-tag-person-selected-dark: rgb(224, 72, 7);
  --color-tagchip-person-background: rgba(239, 108, 0, 0.08);
  --color-tagchip-person-text: rgba(182, 64, 0, 1);
  --color-tagchip-person-border: rgba(230, 81, 0, 1);

  // Hoistings
  --color-tag-hoistings-background: rgba(248, 250, 255, 1);
  --color-tag-hoisting-font: rgba(100, 130, 160, 1);
  --color-tag-hoisting-selected-background: rgba(235, 240, 255, 1);
  --color-tag-hoisting-selected-border: rgba(120, 150, 190, 1);
  --color-tag-hoisting-selected-dark: rgba(70, 100, 130, 1);

  // vehicle
  --color-tag-vehicle-background: rgba(255, 255, 245, 1);
  --color-tag-vehicle-font: rgba(160, 120, 50, 1);
  --color-tag-vehicle-selected-background: rgba(255, 248, 230, 1);
  --color-tag-vehicle-selected-border: rgba(200, 160, 70, 1);
  --color-tag-vehicle-selected-dark: rgba(140, 100, 35, 1);


  --color-tag-events-selected-background: rgb(217, 217, 217);
  --color-tag-events-selected-border: rgb(153, 153, 153);
  --color-tag-events-selected-dark: rgb(179, 179, 179);


  --color-icon-background: #E5F3FF;
  --color-icon-color: #001A33;

  --color-chart-primary: #008FFB;

  --box-shadow-right: inset 0px -1px 0px 1px rgba(100, 121, 143, 0.122);
  --box-shadow-bottom: inset 0px -1px 0px 0px rgba(100, 121, 143, 0.122);

  --color-upload-outline: #B3B3B3;
  --color-user-status-not-confirmed: #FF9800;
  --color-last-used-saved-search-icon: rgba(153, 153, 153, 1);
  --color-saved-search-icon: #333333;
  --color-saved-search-delete-bg: #deeefd;
  --color-saved-search-drop-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  --color-expanded-filters-shadow: 0px 1px 1rem 0px rgba(0, 0, 0, 0.16);
  --color-expanded-filters-border: #0000001F;
  --color-smart-image-overlay: rgba(255, 255, 255, 0.7);
  --color-search-tag-separator: rgba(0, 0, 0, 0.56);
  --color-search-section-title: rgb(217, 217, 217);
  --color-smart-image-overlay-bg: rgba(25, 25, 25, 0.75);
  --color-timeline-bar-hover-bg: rgb(136, 212, 255);
  --color-timeline-carousel-selected-border: rgba(102, 181, 255, 1);
  --color-timeline-image-zoom-button-bg: rgba(255, 255, 255, 0.7);

  --color-toaster-success-text: #112214;
  --color-toaster-error-text: #410002;
  --color-toaster-success-background: #DDEEE0;
  --color-toaster-error-background: #FFDAD6;
  --color-toaster-success-icon: #44884F;
  --color-toaster-error-icon: #861313;

  --color-default-mui-button-hover-bg: rgba(2, 136, 209, 0.04);
  --color-primary-outlined-button-bg: rgba(0, 0, 0, 0.12);

  --color-non-compliance-by-date-chart-series: #006AFF;

  --color-status-report-summary-title-border: rgba(217, 217, 217, 1);

  --color-bg-video-controls: linear-gradient(0deg, rgba(60, 60, 60, 0.9) 0%, rgba(70, 70, 70, 0.7) 45%, rgba(100, 100, 100, 0) 100%);

  --color-conxai-footer-bg: #101828;

  --color-chatbot-fullscreen-mask: rgba(217, 217, 217, 0.8);
  --color-chatbot-open-btn-bg: linear-gradient(263.99deg, #0084FF 2.37%, #004F99 88.28%);
  --color-chatbot-open-btn-hover-shadow: 0 0.4rem 0.6rem 0 rgba(0, 0, 0, 0.25);
  --color-chatbot-header-bg: linear-gradient(269.25deg, #0084FF -5.87%, #004F99 99.77%);
  --color-user-message-bg: rgba(100, 181, 246, 1);
  --color-chatbot-bot-message-bg: rgba(227, 242, 253, 1);
  --color-chatbot-message-action-button-text: rgba(69, 75, 88, 1);
  --color-chatbot-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.1);
  --color-chatbot-compose-form-border: rgba(119, 111, 105, 0.28);
  --color-chatbot-loading-complete: rgba(100, 181, 246, 1);
  --color-chatbot-loading-pending: rgba(100, 100, 100, 0.1);

}