.chart {
  padding: 0;
}

.chartContainer {
  display: flex;


  .yaxisTitle {
    transform: rotate(-180deg);
    writing-mode: vertical-lr;
    text-align: center;
  }

  .chartWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.legend {
  display: flex;
  align-items: center;
  gap: var(--spacing-4);
  margin-left: 2rem;

  .marker {
    width: var(--spacing-12);
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    background-color: var(--color-non-compliance-by-date-chart-series);
  }
}