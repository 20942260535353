.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
}

.actions {
  padding: var(--spacing-24);
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.email {
  background-color: var(--color-grey-light-2);
  padding: var(--spacing-8);
  border-radius: 0.4rem;
}