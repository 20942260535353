.table {
  font-size: 1.4rem;
  border-color: var(--color-middle-grey);

  --DataGrid-rowBorderColor: white;

  :global(.MuiTablePagination-selectLabel),
  :global(.MuiTablePagination-input),
  :global(.MuiTablePagination-displayedRows) {
    font-size: 1.4rem;
  }
}

:global(.MuiTablePagination-menuItem) {
  font-size: 1.4rem;
}

.columnHeader {
  background-color: var(--color-grey-light-2);
  color: var(--color-neutral-10);
}

.sortIcon {
  color: var(--color-primary);
}