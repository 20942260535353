.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
}

.formContainer {
  display: flex;
  flex-direction: row;

  gap: var(--spacing-24);

  background-color: var(--color-project-nav);
  align-items: start;
  border-radius: 0.6rem;
  padding: var(--spacing-24);

  & > div {
    flex: 1;
    margin: 0;
    padding: 0;
  }
}

.occupancy {
  background-color: var(--color-white);
  border-radius: 0.6rem;

  fieldset {
    border-radius: 0.6rem;
    border: 0;
  }

  p {
    position: absolute;
  }
}

.actions {
  padding: var(--spacing-24);
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.endAdornment {
  display: flex;
  align-items: center;
}

.dateInput:not(:global(.Mui-disabled)) .endAdornment {
  color: var(--color-primary);
}

.endIcon {
  font-size: 1.8rem;
}
