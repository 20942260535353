.status {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @mixin icon {
    font-size: 2rem;
  }

  .progress {
    @include icon();
    flex-shrink: 0;
  }

  .completed {
    @include icon();
    color: var(--color-green-500);
  }

  .failed {
    @include icon();
    color: red;
  }
}
