$aimistake-height: 3rem;
$open-assistant-btn-height: 7rem;
$transition-duration: 0.4s;

.openIcon {
  height: $open-assistant-btn-height;
  width: 100%;
  bottom: 0;
  right: 0;
  border: none;
  background: var(--color-chatbot-open-btn-bg);
  color: var(--color-white);
  font-size: 1.4rem;
  display: flex;
  gap: var(--spacing-12);
  align-items: center;
  padding: var(--spacing-16);
  cursor: pointer;
  visibility: visible;
  transition: all $transition-duration;
  transition-delay: 0s, $transition-duration;
  overflow: hidden;

  .avatar {
    opacity: 1;
    transition: all $transition-duration;
  }

  &:hover {
    box-shadow: var(--color-chatbot-open-btn-hover-shadow);
  }

  svg {
    font-size: 4rem;
  }
}

.container {
  position: fixed;
  bottom: 4rem;
  right: var(--spacing-24);
  width: 20rem;
  height: $open-assistant-btn-height;
  box-shadow: var(--color-chatbot-shadow);
  z-index: 50; // come on top of nav menu
  visibility: hidden;
  overflow: hidden;
  border-radius: 3.6rem;
  border-bottom-right-radius: 0.6rem;
  transition: all $transition-duration, visibility 0s;


  &__visible {
    width: 42.5rem;
    height: 52rem;
    visibility: visible;
    border-radius: var(--radius-lg);
    background-color: var(--color-white);

    .openIcon {
      height: 6rem;
      bottom: calc(100% - 6rem);
      visibility: hidden;
      left: 0;

      .avatar {
        opacity: 0;
      }
    }

    .header {
      visibility: visible !important;
    }
  }

  .inner {
    width: 42.5rem; //fix for composer not resizing
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: var(--radius-lg);
    overflow: hidden;
    position: absolute;
    transition: all 0s;
    transition-delay: calc($transition-duration / 4);
  }

  .header {
    visibility: hidden;
    background: var(--color-chatbot-header-bg);
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-16);
    transition: visibility 0s;
    transition-delay: $transition-duration;
    z-index: 50;

    .rightActionIcon {
      font-size: 1.8rem;
      color: var(--color-white);
    }

    .title {
      display: flex;
      align-items: center;
      gap: var(--spacing-16);
      color: var(--color-white);

      svg {
        font-size: 1.8rem;
      }
    }
  }

  .content {
    padding: var(--spacing-16);
    background-color: var(--color-white);
    flex-grow: 1;
    min-height: 0;

    :global(.aui-thread-viewport) {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-16);
      height: 100%;
      overflow-y: auto;
      padding-bottom: 4rem;
    }

    :global(.aui-root) {
      position: relative;
      height: 100%;
    }

    :global(.aui-thread-viewport-footer) {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      :global(.aui-thread-scroll-to-bottom) {
        display: none;
      }
    }

    :global(.aui-composer-send) {
      width: 4rem;
      background: none;
      border: none;
      transform: rotate(-45deg);

      :global(.aui-sr-only) {
        display: none;
      }
    }

    :global(.aui-composer-root) {
      display: flex;
      gap: var(--spacing-12);
      padding: var(--spacing-8);
      border: 0.1rem solid var(--color-chatbot-compose-form-border);
      border-radius: var(--radius-lg);
      background-color: var(--color-white);
    }

    :global(.aui-assistant-message-root) {
      display: grid;
      grid-template-columns: 4rem 1fr;
      gap: var(--spacing-12);
      row-gap: var(--spacing-8);
    }

    :global(.aui-composer-input) {
      padding: var(--spacing-8);
      font-family: 'Inter';
      font-size: 1.4rem;
      flex-grow: 1;
      border: none;
      outline: none;
      resize: none;
    }

    :global(.aui-user-message-root),
    :global(.aui-assistant-message-content) {
      padding: var(--spacing-12);
      border-radius: var(--radius-lg);
      background-color: var(--color-user-message-bg);
      color: var(--color-white);
      font-family: 'Inter';
    }

    :global(.aui-user-message-root) {
      border-top-right-radius: 0;
    }

    :global(.aui-assistant-message-content) {
      background-color: var(--color-chatbot-bot-message-bg);
      color: var(--color-text-primary);
      border-top-left-radius: 0;

      :global(.aui-md-running::after) {
        content: "";
        display: block;
        width: 1.2rem;
        aspect-ratio: 1;
        border-radius: 50%;
        animation: l5 1s infinite linear alternate;
        margin-left: 2rem;

        @keyframes l5 {
          0% {
            box-shadow: 2rem 0 var(--color-chatbot-loading-complete), -2rem 0 var(--color-chatbot-loading-pending);
            background: var(--color-chatbot-loading-complete)
          }

          33% {
            box-shadow: 2rem 0 var(--color-chatbot-loading-complete), -2rem 0 var(--color-chatbot-loading-pending);
            background: var(--color-chatbot-loading-pending)
          }

          66% {
            box-shadow: 2rem 0 var(--color-chatbot-loading-pending), -2rem 0 var(--color-chatbot-loading-complete);
            background: var(--color-chatbot-loading-pending)
          }

          100% {
            box-shadow: 2rem 0 var(--color-chatbot-loading-pending), -2rem 0 var(--color-chatbot-loading-complete);
            background: var(--color-chatbot-loading-complete)
          }
        }
      }

      ol,
      ul {
        margin-left: var(--spacing-12);
      }
    }

    :global(.aui-assistant-action-bar-root) {
      margin-left: calc(4rem + var(--spacing-12));

      :global(.aui-button) {
        border: none;
        background: none;
        cursor: pointer;

        svg {
          width: 1.6rem;
          color: var(--color-chatbot-message-action-button-text);
        }

        :global(.aui-sr-only) {
          display: none;
        }
      }
    }

    :global(.aui-thread-welcome-center) {
      display: flex;
      gap: var(--spacing-12);
    }

    :global(.aui-thread-welcome-message) {
      background-color: var(--color-chatbot-bot-message-bg);
      border-radius: var(--radius-lg);
      border-top-left-radius: 0;
      padding: var(--spacing-8) var(--spacing-12);
      flex-grow: 1;
    }

    :global(.aui-avatar-image) {
      width: 3.8rem;
    }
  }

  &__fullscreen {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-chatbot-fullscreen-mask);
    padding: var(--spacing-32);

    .inner {
      width: 100%;
      position: relative;
    }

    :global(.aui-root) {
      height: calc(100% - $aimistake-height) !important;
      width: 80%;
      margin: auto;
    }

    :global(.aui-thread-viewport) {
      gap: var(--spacing-32) !important;
    }

    :global(.aui-composer-root) {
      padding-top: var(--spacing-16) !important;
      padding-bottom: var(--spacing-16) !important;
    }

    .aimistake {
      height: $aimistake-height;
      display: flex;
      justify-content: center;
      align-items: end;
      color: var(--color-grey-dark-3);
    }
  }
}