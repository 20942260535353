.card {
  border-radius: var(--radius-lg);
  box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.1);
  padding: var(--spacing-8);
  padding-top: var(--spacing-4);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  cursor: pointer;
  border-top-width: 0.3rem;
  border-top-style: solid;

  &__selected {
    outline: 0.1rem solid transparent;
  }

  .description {
    font-size: 1.2rem;
    color: var(--color-text-secondary);
    background-color: var(--color-project-nav);
    padding: var(--spacing-4);
    border-radius: var(--radius);
  }

  .selectedText {
    font-size: 1rem;
    color: var(--color-text-secondary);
    display: flex;
    align-items: center;
    gap: var(--spacing-8);
    border-top: 0.1rem solid var(--color-divider);
    padding-top: var(--spacing-4);

    svg {
      font-size: 3rem;
    }
  }
}