.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;

  flex-direction: column;
  gap: var(--spacing-24);
  overflow-y: auto;
  margin-top: calc(var(--spacing-8) * -1); // for box-shadow of fitlers to be visible
}

.skeleton {
  &__searchFor {
    display: flex;
    justify-content: flex-start;
    gap: var(--spacing-24);
  }
}