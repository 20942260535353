@mixin chartBackground {
  background-color: var(--color-grey-100);
  padding: var(--spacing-16);
  border-radius: var(--radius-2);
}

@mixin charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-16);
}

@mixin gap16 {
  display: flex;
  gap: var(--spacing-16);
  flex-direction: column;
}

.noCamera {
  padding: var(--spacing-16);
  border-radius: var(--radius-2);
  text-align: center;
  background-color: var(--color-grey-100);
}

.container {
  @include gap16();
  font-size: 1.4rem;

  .summary {
    @include chartBackground();
    @include charts()
  }

  .remainingCharts {
    @include gap16();
    @include chartBackground();

    .charts {
      @include charts()
    }
  }

}