.container {
  padding: var(--spacing-16);

  .search {
    margin-bottom: var(--spacing-16);
  }

  .listItem {
    margin-bottom: 1rem;
  }
}