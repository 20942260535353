.container {
  position: sticky;
  top: 0;
  z-index: 5;
  padding: 8px 8px 0 8px;
  background-color: var(--color-white);

  &__absolute {
    position: absolute;
    width: 100%;
  }
}

.filtersWrapper {
  transition: height 0.3s ease-in-out;
  box-shadow: var(--color-expanded-filters-shadow);
  overflow: hidden;
  border-radius: 1.2rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.filters {
  border-radius: 1.2rem;
  overflow: hidden;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0.1rem solid var(--color-expanded-filters-border);
  box-shadow: none;
}

.search {
  margin-bottom: var(--spacing-12);
}

.scrollToTopBtn {
  border-right: 0.1rem solid var(--color-expanded-filters-border);
}

.date {
  font-weight: 500;
}