$shadow-size: 1rem;

.actionsContainer {
  margin-top: -2.4rem; // `gap` from parent container
  position: sticky;
  top: 0;
  z-index: 6;
  font-size: 1.4rem;
  padding: 0 var(--spacing-8) var(--spacing-8) var(--spacing-8);
  top: 0.8rem; // top is used as padding-top to prevent height changes
  background-color: var(--color-white);
  transition: top 0.3s ease-in-out;


  &__expanded {

    // when expanded, this is to show top shadow of actions
    &::before {
      content: "";
      position: absolute;
      top: -$shadow-size;
      left: 0.8rem;
      width: calc(100% - 1.6rem);
      height: calc($shadow-size - 0.1rem); // 0.1rem so that border will be visible
      background-color: var(--color-project-nav);
    }
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--spacing-16);
  box-shadow: var(--color-expanded-filters-shadow);
  background-color: var(--color-project-nav);
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;

  &.actionsMinimized {
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem;
  }
}

.scrollToTopBtn {
  border-right: 0.1rem solid var(--color-expanded-filters-border);
}

.actionButtons {
  :global(.MuiButtonGroup-firstButton) {
    border-color: var(--color-expanded-filters-border);
  }
}

.buttonIcon {
  font-size: 1.8rem !important;
}

.date {
  font-weight: 500;
}