.formControl {
  font-size: 1.4rem;
}

.selectMenu {
  max-height: 30rem;
  overflow-y: auto;
  padding-top: 0;

  .menuItem {
    :global(.MuiListItemText-primary) {
      font-size: 1.6rem;
    }

    :global(.MuiSvgIcon-root) {
      font-size: 2rem;
      width: 2rem;
      height: 2rem;
    }

    &.selectAllMenuItem {
      position: sticky;
      width: 100%;
      border-bottom: 0.1rem solid var(--color-grey-dark-7);
      background-color: var(--color-white);
      top: 0;
      z-index: 10;
    }
  }
}

.select {
  font-size: inherit;
  height: 4.8rem;
  background-color: var(--color-white);
}

:global(.con-multiple-select) {

  :global(.MuiOutlinedInput-root) {
    :global(.MuiSelect-outlined.MuiSelect-select) {
      font-size: inherit;
      min-height: auto;
      padding-right: var(--spacing-24);
    }
  }

  :global(.MuiInputBase-adornedStart) {

    .cameraIcon {
      font-size: 2rem;
      margin-right: var(--spacing-8);
    }

    :global(.MuiSelect-iconOutlined) {
      top: 50%;
      font-size: 1.6em;
      transform: translateY(-50%);
    }
  }
}