$header-height: 64px;
$filter-toolbar-height: 52px;


.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
  height: calc(100vh - $header-height - $filter-toolbar-height);
  overflow-y: auto;

  .detectionTimeline {
    background-color: var(--color-grey-light-2);
    border-radius: var(--spacing-16);
    padding: var(--spacing-8) var(--spacing-16);
  }

  .imageSection {
    flex-grow: 1;
    overflow: auto;
  }
}