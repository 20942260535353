.formControl {
  font-size: 1.4rem;
}

.menuItem {
  font-size: 1.4rem;

  :global(.MuiSvgIcon-root) {
    font-size: 2rem;
    width: 2rem;
    height: 2rem;
  }
}

.select {
  font-size: inherit;
  height: 4.8rem;
  width: 22rem;
  background-color: var(--color-white);
}