$header-height: 4.8rem;
$table-body-height: 54rem;

.container {
  box-shadow: 0 0.2rem 0.8rem 0 var(--color-grey-dark-4);
  border-radius: 0.6rem;
  background-color: var(--color-white);
  overflow: hidden;

  .empty {
    height: calc($table-body-height + $header-height);
    text-align: center;
    padding: 2rem;
  }

  table {
    border-collapse: collapse;
    width: 100%;

    tr {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    thead {
      height: $header-height;
      width: 100%;

      tr {
        width: 100%;

        th {
          font-weight: 500;
          background-color: var(--color-grey-light-2);
          border-bottom: 0.1rem solid var(--color-divider);
          padding: var(--spacing-12) var(--spacing-16);
        }
      }
    }

    tbody {
      display: block;
      height: $table-body-height;
      overflow-y: auto;

      tr:hover {
        cursor: pointer;
        background-color: var(--color-grey-light-2);
      }

      .selected {
        background-color: var(--color-grey-light-2);
      }

      td {
        padding: var(--spacing-12) var(--spacing-16);
        text-overflow: ellipsis;
        overflow-x: hidden;
        text-wrap: nowrap;

        .entryIcon {
          color: var(--color-primary-50);
        }
      }

      .time {
        display: flex;
        font-weight: 500;
        align-items: center;
        gap: var(--spacing-8);

        svg {
          font-size: 2rem;
        }
      }
    }
  }
}