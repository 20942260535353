.cameraInfo {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--spacing-8) var(--spacing-24);
  margin-bottom: 2rem;
  background-color: var(--color-grey-100);
  padding: var(--spacing-12) var(--spacing-16);
  align-items: center;
  border-radius: var(--radius-lg);

  .label {
    font-weight: 500;
  }
}