.root {
  background-color: var(--color-white);
  border-radius: var(--radius-lg);
  box-shadow: var(--card-shadow);
  padding: var(--spacing-16);
  min-width: 0;

  .heading {
    display: flex;
    justify-content: space-between;
    min-height: 5.2rem;

    .title {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }
}