.root {
  padding: var(--spacing-16) 0;

  .select {
    margin-bottom: var(--spacing-16);
  }

  .charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-16);
    grid-template-rows: 22rem;

    :global(.MuiSkeleton-root) {
      transform: none;
      border-radius: var(--radius-2);
    }
  }
}