.container {
  display: flex;
  flex: 1;

  flex-direction: column;

  gap: var(--spacing-24);

  border-radius: 1.2rem;
}

.tabs {

  .tab {
    background-color: var(--color-grey-light-2);

    &:global(.Mui-selected) {
      color: var(--color-primary-selected);
      background-color: var(--color-primary-selected-background);
    }
  }
}


.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);

  &__item {
    display: flex;
    flex-direction: row;

    gap: var(--spacing-24);

    padding: var(--spacing-8) 0;

    &_letter {
      padding: 0 var(--spacing-8);
    }

    &_tags {
      display: flex;
      flex-wrap: nowrap;
      gap: var(--spacing-8);
    }

  }
}