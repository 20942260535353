.filters {
  display: flex;
  background-color: var(--color-project-nav);
  align-items: start;
  border-radius: 0.6rem;
  margin-bottom: var(--spacing-32);
  gap: var(--spacing-16);

  .resetBtn {
    margin-top: var(--spacing-12);

    svg {
      font-size: 1.8rem;
    }
  }
}