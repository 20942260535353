.container {
  display: flex;
  gap: var(--spacing-8);
  flex-grow: 1;
  min-height: 0;
  background-color: var(--color-grey-light-2);
  padding: var(--spacing-16);
  padding-left: var(--spacing-8);
  border-radius: var(--radius-lg);

  .leftSection {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);

    .cards {
      display: flex;
      gap: var(--spacing-16);
      width: 20rem;
      flex-direction: column;
      overflow-y: auto;
      padding: var(--spacing-8);
      padding-top: 0;

      .overlapWarning {
        padding: 0 var(--spacing-8);
        font-size: 1.2rem;
      }
    }

    .actions {
      display: flex;
      gap: var(--spacing-8);
      align-items: end;
      flex-shrink: 0;
      padding-top: var(--spacing-4);
    }
  }


  .image {
    width: 100%;
  }

}