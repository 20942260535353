.imageCard {
  border-radius: var(--spacing-8);
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-grey-light-2);
  justify-self: stretch;
  position: relative;

  &__aligncenter {
    align-items: center;
  }
}