.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-16);

  .total {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: right;
  }
}