.container {
  background-color: var(--color-grey-100);
  border-radius: var(--radius-lg);

  .options {
    display: flex;
    gap: var(--spacing-16);
    flex-wrap: wrap;
    padding: var(--spacing-16);

    .option {
      padding: var(--spacing-8);
      background-color: var(--color-white);
      box-shadow: var(--card-shadow-status-report);
      border-radius: var(--radius-lg);
      cursor: pointer;
      border: 0.2rem solid transparent;

      &__selected {
        border: 0.2rem solid var(--color-primary-50);
      }

      .label {
        margin-bottom: var(--spacing-8);
      }
    }
  }

  .opacityAndColor {
    display: flex;
    flex-basis: 20rem;
    gap: var(--spacing-24);
    padding: var(--spacing-8) var(--spacing-16);

    .opacity,
    .color {
      width: 20rem;
      display: flex;
      align-items: center;
      gap: var(--spacing-8);
      color: var(--color-text-secondary);
    }

    .color {
      gap: 0;
    }

    .squareIcon {
      font-size: 3rem;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-8) var(--spacing-16);
    border-radius: var(--radius-lg);

    .heading {
      font-weight: 500;
    }

    .actions {
      display: flex;
      gap: var(--spacing-8);
    }
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}