.root {
  display: flex;
  flex-direction: column;

  width: 100%;
  background-color: var(--color-grey-100);
  padding: var(--spacing-16);
  overflow-y: auto;

  .tabContent {
    min-height: 0;
    padding-top: var(--spacing-16);
    flex-grow: 1;

    .tabPanel {
      height: 100%;
    }
  }
}