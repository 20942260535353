.root {
  border: 0.1rem solid var(--color-elevation-outlined);
  border-radius: 0.4rem;
  padding: var(--spacing-8);
  font-size: 1.2rem;

  .time {
    display: flex;
    align-items: center;
    gap: var(--spacing-4);
    font-weight: 500;
    margin-bottom: var(--spacing-4);
  }

  .entryIcon {
    color: var(--color-primary-50);
  }

  .countWrapper {
    display: flex;
    align-items: center;
    gap: var(--spacing-4);

    svg {
      font-size: 1.4rem;
    }

    .value {
      flex-grow: 1;
      text-align: right;
      font-weight: 500;
    }
  }

  .netflow {
    border-top: 0.1rem solid var(--color-divider);
    padding-top: var(--spacing-4);
  }
}