.smartImageDetailPage {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.container {
    padding: var(--spacing-24);
    overflow-y: auto;
    flex-grow: 1;
}

.breadcrumbs {
    font-size: 1.6rem;
    padding: var(--spacing-8);
}