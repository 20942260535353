.milestoneSettings {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-16);

  .addIcon {
    font-size: 1.6em;
  }
}

.endAdornment {
  cursor: pointer;

  :global(.Mui-focused) & {
    color: var(--color-primary);
  }
}

.search {
  width: 40%;
  margin-bottom: var(--spacing-16);
}

.addRequestedMilestoneBtn {
  padding: 0;
  line-height: inherit;
}

.deleteIcon {
  font-size: 1.8rem;
}