.container {
  display: flex;
  gap: var(--spacing-16);
  height: 11rem;
  aspect-ratio: 18/9;
  cursor: pointer;
  flex-shrink: 0;

  .time {
    font-size: 1rem;
  }

  .image {
    max-height: 100%;
  }
}