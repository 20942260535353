.dialogPaper {
  width: 80%;
  max-width: 120rem;

  .title {
    display: flex;
    justify-content: space-between;
  }
}

.content {
  .inner {
    display: flex;
    flex-direction: column;
  }

  .prevBtn,
  .nextBtn {
    position: absolute;
    width: 3.6rem;
    min-width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    padding: 0;
    top: 50%;
    cursor: pointer;
    z-index: 10;

    svg {
      font-size: 2rem;
    }
  }

  .prevBtn {
    left: var(--spacing-4);
  }

  .nextBtn {
    right: var(--spacing-4);
  }
}