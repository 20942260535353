.container {
  display: flex;
  gap: var(--spacing-8);

  .weather {
    display: flex;
    gap: var(--spacing-16);
    align-items: center;

    svg {
      font-size: 3rem;
    }
  }

  .section {
    background-color: var(--color-project-nav);
    border-radius: var(--radius-lg);
    padding: var(--spacing-8);
    display: flex;
    gap: var(--spacing-16);
    flex-grow: 1;

    .card {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex-grow: 1;

      gap: var(--spacing-8);
      padding: var(--spacing-16);
      border-radius: var(--radius-lg);
      background-color: var(--color-white);
      box-shadow: var(--card-shadow-status-report);

      .title {
        color: var(--color-text-secondary);
      }

      .content {
        color: var(--color-text-primary-2);
        font-size: 2.4rem;
      }
    }
  }
}