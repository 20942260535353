.header {
  display: flex;
  align-items: center;
  gap: var(--spacing-16);
  justify-content: space-between;

  .titleAndDate {
    display: flex;
    align-items: center;
    gap: var(--spacing-16);
  }

  .betaBadge {
    display: flex;
    gap: var(--spacing-8);
    align-items: center;

    :global(.MuiBadge-badge) {
      top: .2rem;
      right: -.8rem;
    }
  }

  .graybg {
    background-color: var(--color-grey-light-2);
    border-radius: var(--spacing-4);
    padding: var(--spacing-4) var(--spacing-8);
  }

  .actions {
    display: flex;
    gap: var(--spacing-16);
  }
}