.container {
  background-color: var(--color-white);
  border-radius: var(--radius-2);
  padding: var(--spacing-16);
  color: var(--color-text-secondary);

  .title {
    font-size: 1.8rem;
    margin-bottom: var(--spacing-16);
  }

  .chartContainer {
    display: flex;


    .yaxisTitle {
      transform: rotate(-180deg);
      writing-mode: vertical-lr;
      text-align: center;
    }

    .chartWrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;

      .chartHeightFix {
        height: 32rem;
      }

      :global(.apexcharts-xaxis-label) {
        font-size: 1.4rem;
        font-family: 'Inter' !important;
        fill: var(--color-text-secondary);
      }
    }
  }

  .legend {
    display: flex;
    margin-left: 2rem;
    gap: var(--spacing-16);

    .legendItem {
      display: flex;
      align-items: center;
      gap: var(--spacing-4);

      .marker {
        width: var(--spacing-12);
        height: 1.2rem;
        width: 1.2rem;
        border-radius: 50%;
        background-color: var(--color-non-compliance-by-date-chart-series);
      }
    }

  }
}