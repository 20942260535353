.clickable {
  cursor: pointer;
}

.disabledRow {
  cursor: not-allowed;
}

.deleteIcon {
  font-size: 1.8rem;
}