.addNewItem {
  background-color: var(--color-white);
  color: var(--color-text-disabled);
  border-radius: 0.6rem;
  position: relative;
  margin-top: var(--spacing-4);

  .addInfoInput {
    width: 100%;
    line-height: inherit;
    border: none;
    margin-left: calc(var(--spacing-4) * -1);
    padding-left: var(--spacing-8);
  }
}