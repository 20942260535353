.container {
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 26rem;
  height: 26rem;
}
