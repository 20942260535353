.container {
  display: flex;

  flex-direction: column;
  gap: var(--spacing-8);

  align-items: center;

  height: var(--tag-card-height);

  border-radius: 6px;

  background-color: white;
  border: 1px solid var(--color-tag-card-border);

  cursor: pointer;

  transition: all .2s ease-in-out;
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 0.3rem;
  }


  &:hover {
    box-shadow: var(--card-shadow);
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }

}

.icon {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: end;
}

.name {
  display: flex;
  justify-content: center;

  align-items: flex-start;

  font-size: 1.4rem;

  padding: 0 var(--spacing-8) 0;
}

.tagCard {
  &__unknown {
    background-color: var(--color-tag-default-background);
    color: var(--color-tag-default-font);

    &_selected {
      background-color: var(--color-tag-default-selected-background);
      color: var(--color-tag-selected-font);
    }
  }

  &__equipment {
    &:not(&_selected)::before {
      background-color: var(--color-tag-equipment-selected-border)
    }

    &_selected {
      background-color: var(--color-tag-equipment-selected-background);
      border-color: var(--color-tag-equipment-selected-border);
      box-shadow: 0 0 0.2rem var(--color-tag-equipment-selected-dark);
    }
  }


  &__objectsAndTools {
    &:not(&_selected)::before {
      background-color: var(--color-tag-objectsAndTools-selected-border)
    }

    &_selected {
      background-color: var(--color-tag-objectsAndTools-selected-background);
      border-color: var(--color-tag-objectsAndTools-selected-border);
      box-shadow: 0 0 0.2rem var(--color-tag-objectsAndTools-selected-dark);
    }
  }

  &__structures {
    &:not(&_selected)::before {
      background-color: var(--color-tag-structures-selected-border)
    }

    &_selected {
      background-color: var(--color-tag-structures-selected-background);
      border-color: var(--color-tag-structures-selected-border);
      box-shadow: 0 0 0.2rem var(--color-tag-structures-selected-dark);
    }
  }

  &__hoisting {
    &:not(&_selected)::before {
      background-color: var(--color-tag-hoisting-selected-border)
    }

    &_selected {
      background-color: var(--color-tag-hoisting-selected-background);
      border-color: var(--color-tag-hoisting-selected-border);
      box-shadow: 0 0 0.2rem var(--color-tag-hoisting-selected-dark);
    }
  }

  &__vehicle {
    &:not(&_selected)::before {
      background-color: var(--color-tag-vehicle-selected-border)
    }

    &_selected {
      background-color: var(--color-tag-vehicle-selected-background);
      border-color: var(--color-tag-vehicle-selected-border);
      box-shadow: 0 0 0.2rem var(--color-tag-vehicle-selected-dark);
    }
  }

  &__person {
    &:not(&_selected)::before {
      background-color: var(--color-tag-person-selected-border)
    }

    &_selected {
      background-color: var(--color-tag-person-selected-background);
      border-color: var(--color-tag-person-selected-border);
      box-shadow: 0 0 0.2rem var(--color-tag-person-selected-dark);
    }
  }
}
