.list {
  display: grid;
  grid-template-columns: repeat(10, 20rem);
  gap: var(--spacing-8);
}

.skeleton {
  transform: scale(1);
}

.dates {
  display: flex;
  justify-content: space-between;
}