.container {
  position: relative;
  width: 6.4rem;
  background-color: var(--color-white);
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: var(--spacing-24);
  padding: var(--spacing-24) 0;

  &_thin {
    padding: 0;
  }

  &:not(:last-child) {
    box-shadow: var(--box-shadow-bottom)
  }

  .occupancyIcon {
    font-size: 1.2rem;
  }
}