.container {
  margin-top: calc(var(--spacing-24) * -1);

  :global(.MuiDataGrid-cell:focus-within) {
    outline: none;
  }
}

.externalId {
  overflow-x: auto;
}

.editIcon {
  font-size: 2.4rem;
}