.tabs {
    min-height: 3.2rem;
    font-size: 1.4rem;

    :global(.MuiTabs-indicator) {
        background-color: var(--color-primary-50);
    }

    :global(.MuiButtonBase-root) {
        font-size: 1.6rem;
    }
}