.chart {
  display: flex;
  gap: 4.8rem;
  align-items: center;


  .label {
    display: grid;
    grid-template-columns: 1rem 3fr 1fr;
    column-gap: var(--spacing-16);
    margin-bottom: var(--spacing-8);
    align-items: center;
    position: relative;

    .color {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
    }

    .value {
      text-align: right;
    }
  }

}