.filters {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12);
  padding: var(--spacing-16);
  background-color: var(--color-project-nav);
  border-radius: 1.2rem;
  box-shadow: var(--color-expanded-filters-shadow);

  .actions {
    display: flex;
    justify-content: end;
    gap: var(--spacing-16);

    .saveBtn {
      background-color: var(--color-white);
      border-color: var(--color-primary-outlined-button-bg);
    }
  }
}