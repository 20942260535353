.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .cameraInfo {
    height: 14rem;
    transform: scale(1);
    margin-bottom: var(--spacing-16);
  }

  .zoneConfiguration {
    flex-grow: 1;
    transform: scale(1);
  }
}