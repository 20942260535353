.container {
  position: relative;

  .popoverTriggerBtn {
    position: absolute;
    top: 0.1rem;
    right: 0.2rem;
    transition: top 0.2s ease-in-out;
  }

  .personTag:hover+.popoverTriggerBtn {
    top: -1px;
  }
}