:global(.con-date-range-picker .MuiPaper-root ) {
  font-size: 1.6rem;
}

:global(.con-date-range-picker .MuiTypography-root) {
  font-size: 1.4rem;
}

:global(.con-date-range-picker .MuiSelect-standard) {
  font-size: 1.4rem;
}

:global(.con-date-range-picker .MuiSvgIcon-root) {
  font-size: 2rem;
  width: 2rem;
  height: 2rem;
}

:global(.con-date-range-picker .MuiSelect-select) {
  font-size: 1.4rem;
  min-height: auto;
}

:global(.con-date-range-picker .MuiList-root .MuiMenuItem-root) {
  font-size: 1.4rem;
  min-height: auto;
}
