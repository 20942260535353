.container {
  min-width: 20rem;
  display: flex;
}

.textfield {
  font-size: 1.4rem;
  margin-top: 0;
}

.dialog {
  width: auto !important;
  font-size: 1.4rem;
}

.tabPanels {
  width: 32rem;
  overflow-y: auto;
}

.endAdornment {
  display: flex;
  align-items: center;

  .endAdornmentDate {
    color: var(--color-grey-dark-2);
    margin-right: var(--spacing-16);
  }

  .endIcon {
    font-size: 1.8rem;
    color: var(--color-primary);
  }
}

.content {
  display: flex;
  padding: 0;
  height: 35rem;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-grey-light-4);
}

.label {
  padding-right: var(--spacing-8);
}

.leftMenu {
  border-right: 1px solid var(--color-grey-light-4);
}

.menuButton {
  color: var(--color-primary-40);

  &:global(.Mui-selected) {
    background-color: var(--color-grey-light-2);
    color: var(--color-text-primary)
  }
}

.calendar {
  width: 30rem;
}

.input {
  border-radius: 0.6rem;
  background-color: var(--color-white);
}