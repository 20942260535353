.container {
  padding: var(--spacing-24) 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
  grid-gap: var(--spacing-24) var(--spacing-24);
  align-content: stretch;
  width: 100%;

  &>div {
    display: block;
    cursor: pointer;
  }
}

.imagesSection {
  font-size: 1.4rem;

  &__header {
    position: sticky;
    top: 6.1rem;

    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    z-index: 1;
    background-color: var(--color-white);

    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    margin-bottom: var(--spacing-4);

    &__counter {
      color: var(--color-grey-dark-2);
      font-size: 1.2rem;
    }

    &__title {
      position: relative;
      display: inline-flex;
      align-items: center;

      user-select: none;
      cursor: pointer;
      padding: var(--spacing-8) var(--spacing-4);
      font-size: 1.6rem;
      color: var(--color-text-primary);

      svg {
        font-size: 2.4rem;
        margin-right: var(--spacing-4);
        fill: var(--color-grey-dark-2)
      }

      &:before {
        border-radius: 1rem;
        transition: all .4s ease;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale(0);
        opacity: 0;
        content: '';
        background-color: var(--color-grey-dark-5);
        z-index: -1;
      }

      &:hover {
        &:before {
          transform: scale(1);
          opacity: 1;
        }
      }

      margin-bottom: var(--spacing-2);
    }
  }
}

.imagesWrapper {
  width: 100%;

  &_hide {
    display: none;
  }
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-secondary);
  padding: var(--spacing-12) 0;
}

.ppeFeatureNotAvailable {
  background-color: var(--color-warning-alert-background);
  padding: var(--spacing-12) var(--spacing-16);
  border-radius: var(--radius-2);
  display: flex;
  align-items: center;
  gap: var(--spacing-16);
  color: var(--color-warning-alert-text);
  margin-top: var(--spacing-8);

  .ppeNotAvailableIcon {
    font-size: 2.4rem;
    font-weight: 700;

  }
}

.cameraAndImagesContainer {
  margin-top: -2.4rem;
}