.root {
  border: 0.1rem solid var(--color-elevation-outlined);
  border-radius: 0.4rem;
  padding: var(--spacing-8);
  font-size: 1.2rem;

  .time {
    display: flex;
    align-items: center;
    gap: var(--spacing-4);
    font-weight: 500;
    margin-bottom: var(--spacing-4);
  }

  .value {
    font-weight: 500;
  }
}