.sidebar {
  width: 0;
  background-color: var(--color-project-nav-active);
  padding: var(--spacing-24) var(--spacing-24);
  overflow: hidden;
  transform: translateX(-36rem);
  visibility: hidden;
  margin-left: -4.8rem;

  /* Disable transition for initial render */
  transition: none;
  border-radius: 1.4rem;
  margin-bottom: var(--spacing-12);
}

.sidebar__animated {
  /* Re-enable transition when class is added */
  transition: transform .6s ease, width .35s ease;
}

.sidebar__show {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  width: 36rem;
  position: relative;
  visibility: visible;
  transform: translateX(0);
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.tab {
  padding: var(--spacing-4) var(--spacing-16);
  color: var(--color-text-primary);
}

.tabPanel {
  min-height: 0;
  overflow-y: auto;
}
