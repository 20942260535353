.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  margin-top: calc(var(--spacing-8) * -1);
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;

  gap: var(--spacing-24);
  padding: var(--spacing-8);
}

.chartContainer {
  width: 100%;
  height: 500px;
}