.container {
  position: relative;
}

.baseline {
  stroke: var(--color-grey-dark-4);
  stroke-linecap: round;
}

.separator {
  fill: var(--color-white);
  opacity: 1;
}

.item {
  fill: var(--color-chart-primary);
  opacity: .85;
  transform-origin: center;
  transition: all .3s ease-out;

  &__empty {
    fill: var(--color-grey-dark-7);
  }

  &__absent {
    visibility: hidden;
  }

  &:hover {
    fill: var(--color-timeline-bar-hover-bg);
  }
}

.selected {
  stroke: var(--color-primary-20);
  stroke-linecap: round;
  stroke-width: 2;
}

//@keyframes itemAppear {
//  0% {
//    transform: scaleY(0);
//  }
//  100% {
//    transform: scaleY(1);
//  }
//}

.footerText {

  &__text {
    font-size: 1.2rem;
    fill: var(--color-text-primary);

    &_left {
      text-anchor: start;
    }

    &_right {
      text-anchor: end;
    }
  }
}

.tooltip {
  position: absolute;
  opacity: 0;

  background-color: var(--color-white);
  border: 1px solid var(--color-elevation-outlined);
  border-radius: 4px;
  padding: var(--spacing-4) var(--spacing-8);
  font-size: 1.4rem;
  transform: translate(1rem, 1rem);
  z-index: 10;
  transition: all .1s linear;
  box-shadow: 0 0.1rem 1rem 0 var(--color-grey-dark-4);

  &:hover {
    opacity: 1;
  }

  &_active {
    opacity: 1;
  }

  &_bottom-left {
    transform: translate(-100%, 1rem);
  }
}