.milestoneName {
  margin-bottom: var(--spacing-16);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
}

.actions {
  padding: var(--spacing-24);
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.endAdornment {
  display: flex;
  align-items: center;
}

.dateInput:not(:global(.Mui-disabled)) .endAdornment {
  color: var(--color-primary);
}

.endIcon {
  font-size: 1.8rem;
}