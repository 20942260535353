.root {
  height: 100%;
}

.total {
  font-size: 1.8rem;
  font-weight: 500;
  text-align: right;
}

.chartContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}