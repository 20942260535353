.container {
  padding: var(--spacing-16);
  background-color: var(--color-white);
  border-radius: var(--radius-2);

  .titleContainer {
    display: flex;
    justify-content: space-between;

    .title {
      margin-bottom: var(--spacing-32);
    }

    .explainBtn {
      border-radius: var(--spacing-8);
      background-color: var(--color-chatbot-bot-message-bg);
      width: 3.2rem;
      height: 3.2rem;
      color: var(--color-primary-main);
      padding: var(--spacing-4);

      svg {
        font-size: 1.8rem;
      }

      &:hover {
        background-color: var(--color-primary-main);
        color: var(--color-white);
      }
    }
  }
}