.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  font-size: 1.4rem;
}

.title {
  font-size: 1.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchName {
  margin-top: var(--spacing-24);
  margin-bottom: var(--spacing-16);
}

.closeBtn {
  color: var(--color-primary);
  border: 0.1rem solid var(--color-grey-light-4);
  border-radius: 0.4rem;
}

.actions {
  padding: var(--spacing-24);
}