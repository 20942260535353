.root {

  input {
    display: inline-block;
    width: 8.2rem;
    font-size: 2.4rem;
    color: var(--color-text-primary-2);
    font-family: "Inter", sans-serif;
    padding: var(--spacing-8);
    text-align: center;
  }

  :global(.MuiInputBase-formControl) {
    padding-right: 0;
  }

  :global(.Mui-readOnly .MuiOutlinedInput-notchedOutline) {
    border-width: 0;
  }

  :global(.MuiOutlinedInput-notchedOutline) {
    border-width: 0;
  }

  :global(.MuiInputAdornment-root) {
    display: none;
  }
}